import React from "react";
import { BaseHeader } from "../baseHeaders";
import { ModalHeaderProps } from "../baseHeaders/types";

export const ModalHeader = ({
  qaLabel = "modalHeader",
  title,
  subtitle = "",
  subtitleType = "description",
  hasRoundedCorners = false,
  hasShadow = false,
  hasBorderBottom = true,
  linkUrl,
  onBack,
  onClose,
  headerLeftElement
}: ModalHeaderProps) => (
  <BaseHeader
    qaLabel={qaLabel}
    title={title}
    subtitle={subtitle}
    hasRoundedCorners={hasRoundedCorners}
    hasShadow={hasShadow}
    subtitleType={subtitleType}
    linkUrl={linkUrl}
    onBack={onBack}
    onClose={onClose}
    hasBorderBottom={hasBorderBottom}
    headerLeftElement={headerLeftElement}
  />
);

export { ModalHeaderProps };
export default ModalHeader;
