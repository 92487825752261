import styled from "styled-components/native";
import { margin } from "styled-system";
import { View } from "react-native";
import { stylesTagVariants, styleFitContent } from "../styled-components";

export const TagCircleContainer = styled(View)`
  ${margin}
  ${stylesTagVariants}
  ${styleFitContent}
  border-radius: 99px;
  padding: ${({ theme }) => theme.space["space-1"]};
  align-self: flex-start;
`;
