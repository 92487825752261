import React, { useContext, useEffect, forwardRef } from "react";
import { TouchableHighlight } from "react-native";
import { useTheme } from "../../theming";
import { useQaLabel } from "../../hooks/useQaLabel";
import { ButtonBarProps, ButtonBarCompoundComponent } from "./types";
import { StyledTouchableHighlight, StyledText } from "./styled-components";
import { ButtonBarGroup, GroupContext } from "./buttonBarGroup";
import { useEvents } from "../../hooks/useEvents";

export const ButtonBar = forwardRef<TouchableHighlight, ButtonBarProps>(
  ({ children, isSelected = false, value, qaLabel, ...rest }, ref) => {
    const viewTestProps = useQaLabel(qaLabel);
    const { colors } = useTheme();
    const { isCondensed = false } = useContext(GroupContext);
    const { events, eventsHandlers, setEvents } = useEvents({});
    const { selectedValue, onChange = () => {} } = useContext(GroupContext);

    useEffect(() => {
      setEvents({ ...events, isSelected });
    }, [isSelected]);

    useEffect(() => {
      setEvents({
        ...events,
        isSelected: isSelected || selectedValue === value
      });
    }, [selectedValue]);

    const pressHandler = () => {
      onChange(value);
    };

    return (
      <StyledTouchableHighlight
        ref={ref}
        {...rest}
        {...viewTestProps}
        {...eventsHandlers}
        {...events}
        isCondensed={isCondensed}
        onPress={pressHandler}
        accessibilityRole="button"
        underlayColor={colors.blue_accent[200]}
        onLongPress={pressHandler}
      >
        {typeof children === "string" ? (
          <StyledText {...events} isCondensed={isCondensed}>
            {children}
          </StyledText>
        ) : (
          children
        )}
      </StyledTouchableHighlight>
    );
  }
) as ButtonBarCompoundComponent<typeof ButtonBarGroup>;

export { ButtonBarProps };
ButtonBar.Group = ButtonBarGroup;
export default ButtonBar;
