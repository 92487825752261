// @flow
import mediator from "@tvg/mediator";
import type {
  MapFeatureToggles,
  FeatureToggles,
  Messages
} from "@tvg/conf/src/types.js.flow";

import type { CapiActions } from "../../actions/capi";

export type State = {
  featureToggles: MapFeatureToggles | {},
  messages: Messages | [],
  features: FeatureToggles | []
};

export const initialState = {
  featureToggles: {},
  features: [],
  messages: []
};

export default function capiReducer(
  state: State = initialState,
  action: CapiActions
) {
  switch (action.type) {
    case "FEATURES_GET_SUCCESS": {
      mediator.base.dispatch({ type: "FEATURE_TOGGLES_LOADED", payload: {} });
      return { ...state, featureToggles: action.payload.featureToggles };
    }
    case "FEATURES_GET_FAIL": {
      return { ...state };
    }
    case "FEATURES_CHANGE": {
      const featureToggles = {
        ...state.featureToggles,
        [action.payload.toggleName]: action.payload.newValue
      };

      return { ...state, featureToggles };
    }
    case "FEATURES_RESPONSE": {
      return { ...state, features: action.payload.features };
    }
    case "MESSAGES_GET_SUCCESS":
      return { ...state, messages: action.payload.messages };
    case "MESSAGES_GET_FAIL": {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
