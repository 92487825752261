// @flow
import type { Brand } from "./types.js.flow";

export const BRAND_TVG = "tvg";
export const BRAND_FDR = "fdr";
export const BRAND_4NGBETS = "4njbets";
export const BRAND_PABETS = "pabets";
export const BRAND_IOWA = "iowa";
export const BRAND_ALL = [
  BRAND_TVG,
  BRAND_FDR,
  BRAND_4NGBETS,
  BRAND_PABETS,
  BRAND_IOWA
];

export default (hostname: ?string): Brand => {
  if (hostname) {
    if (/(nj|4njbets)(\.|-)/gi.test(hostname)) {
      return BRAND_4NGBETS;
    }
    if (/(pa|pabets)(\.|-)/gi.test(hostname)) {
      return BRAND_PABETS;
    }
    if (/ia(\.|-)/gi.test(hostname)) {
      return BRAND_IOWA;
    }
    if (/fdbox|fanduel(\.|-)|racing/gi.test(hostname)) {
      return BRAND_FDR;
    }
  }
  return BRAND_TVG;
};
