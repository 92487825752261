import React from "react";
import Svg, { Path } from "react-native-svg";
import { colors } from "../../../theming/themes/common";

export const Warning = () => (
  <Svg width="60" height="60" viewBox="0 0 58 56" fill="none">
    <Path
      d="M2.69468 54.2188H55.3025C55.6774 54.2188 55.919 53.8214 55.7464 53.4886L29.4439 2.76225C29.2574 2.40247 28.7427 2.40246 28.5562 2.76223L2.25081 53.4886C2.07822 53.8214 2.31976 54.2188 2.69468 54.2188Z"
      fill={colors.orange[200]}
    />
    <Path
      d="M29.0001 22.25V37.75M29.0001 41.625V47.4375M28.5562 2.76223L2.25081 53.4886C2.07822 53.8214 2.31976 54.2188 2.69468 54.2188H55.3025C55.6774 54.2188 55.919 53.8214 55.7464 53.4886L29.4439 2.76225C29.2574 2.40247 28.7427 2.40246 28.5562 2.76223Z"
      stroke={colors.orange[700]}
      strokeWidth="3"
    />
  </Svg>
);
