// @flow
// $FlowFixMe
import { useEffect, useState } from "react";

const useIsServerSideRender = () => {
  // Start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isServerSideRender, setIsServerSideRender] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, must be on the client to hit this block
    setIsServerSideRender(false);
  }, []);

  return { isServerSideRender };
};

export default useIsServerSideRender;
