"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.onLogoutError = exports.onLogoutSuccess = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _conf = _interopRequireDefault(require("@tvg/conf"));

var _protocolSetter = _interopRequireDefault(require("@tvg/api/protocolSetter"));

var _helper = require("../services/helper");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const onLogoutSuccess = () => ({
  success: {
    status: "success"
  },
  error: null
});

exports.onLogoutSuccess = onLogoutSuccess;

const onLogoutError = error => {
  if (!error.response || !error.response.data || error.response.status >= 500) {
    return {
      error: {
        status: "error",
        message: "Logout request fail"
      },
      success: null
    };
  }

  return {
    error: { ...{
        status: "error"
      },
      ...{
        message: error.response.data.message || "Fail to logout"
      }
    },
    success: null
  };
};

exports.onLogoutError = onLogoutError;

var _default = () => (0, _axios.default)({
  method: "post",
  url: `${(0, _protocolSetter.default)()}${(0, _conf.default)().config().service.usa}/logout`,
  headers: (0, _helper.tvgHeaders)(),
  withCredentials: true
}).then(onLogoutSuccess).catch(onLogoutError);

exports.default = _default;