import React, { FC, memo } from "react";
import { IllustrationContent } from "./illustrationContent";
import { useQaLabel } from "../../hooks";
import { IllustrationProps, IllustrationNames } from "./types";
import { StyledSVG } from "./styled-components";

export const Illustration: FC<IllustrationProps> = memo(
  ({ name, qaLabel, ...rest }) => {
    const viewTestProps = useQaLabel(qaLabel || name);

    return (
      <StyledSVG
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        {...rest}
        {...viewTestProps}
      >
        <IllustrationContent name={name} />
      </StyledSVG>
    );
  }
);

export default Illustration;
export { IllustrationProps, IllustrationNames };
