// @flow

import axios from "axios";
import { attempt } from "./utils";
import type {
  ServiceConfig,
  FeatureToggles,
  Product,
  Device,
  Brand
} from "./types.js.flow";

const parseFeatures = (featuresData): FeatureToggles | false => {
  let features = false;
  const data = attempt(() => JSON.parse(decodeURIComponent(featuresData)));
  if (!(data instanceof Error) && typeof data === "object") {
    features = data;
  }

  return features;
};

const readFeatures = (): FeatureToggles | false => {
  let featuresObject = false;
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.FEATURE_TOGGLES
  ) {
    featuresObject = parseFeatures(window.__TVG_GLOBALS__.FEATURE_TOGGLES);
  }
  return featuresObject;
};

const getFeatures = (
  service: ServiceConfig,
  product: Product,
  device: Device,
  brand: Brand,
  serverSide: boolean
): Promise<FeatureToggles | false> => {
  const features = readFeatures();
  if (features || (typeof window === "undefined" && !serverSide)) {
    return Promise.resolve().then(() => features);
  }
  const requestOptions = {
    url: `${service.capi}/featureToggles/${product}/${device}/${brand}`,
    method: "GET",
    withCredentials: true
  };
  return axios(requestOptions).then((result) =>
    result.data
      ? result.data
      : Promise.reject(new Error("No feature toggles returned"))
  );
};

export default { readFeatures, getFeatures };
