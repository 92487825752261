// @flow
import {
  type TalentPickDataRunners,
  type TalentPickData
} from "@tvg/types/TalentPicks";
import { without } from "lodash";

type FromByToBetSlip = "rebetBet" | "repeatBet" | "runnerClick";
/*
  This method aims to check if an url in internal, relative or external
  according to a whitelist
  If an element of a white list is not included in an url, this url is tested
  to find if it's either relative or external
 */

export const inspectURL = (url: string, whiteList: string[]): string => {
  let returnValue = "isInternal";
  const localProcessUrl = (localUrl) =>
    localUrl.replace("www.", "").replace("www-", "");
  const processedUrl = localProcessUrl(url);
  if (
    !whiteList.some((element) =>
      processedUrl.includes(localProcessUrl(element))
    ) ||
    whiteList.some((element) =>
      processedUrl
        .split(".")
        .slice(1)
        .join(".")
        .includes(localProcessUrl(element))
    )
  ) {
    returnValue = processedUrl.indexOf("/") === 0 ? "isRelative" : "isExternal";
  }

  return url.includes("www") && url.includes("/promos")
    ? "isExternal"
    : returnValue;
};

const getBetSlipFromUrl = (type: FromByToBetSlip) => {
  switch (type) {
    case "rebetBet":
      return "&fromRebetBetClick=true";
    case "repeatBet":
      return "&fromRepeatBetClick=true";
    default:
      return "&fromRunnerClick=true";
  }
};

export const buildBetSlipUrl = (
  trackCode: string,
  raceNumber: string,
  selectedRunner: number,
  isGreyhound: boolean = false,
  betType: number = 10,
  betAmount?: number,
  fromOf: FromByToBetSlip = "runnerClick"
): string => {
  const betamountSearch = betAmount ? `&betamount=${betAmount}` : "";
  const greyhoundsSearch = isGreyhound ? "&greyhounds" : "";
  const fromBy = getBetSlipFromUrl(fromOf);
  return `/betslip/${trackCode}/Day/${raceNumber}?betselections=${selectedRunner}${fromBy}${betamountSearch}&bettype=${betType}${greyhoundsSearch}`;
};

export const buildPoolBetSlipUrl = (
  trackCode: string,
  raceNumber: string,
  isGreyhound: boolean = false,
  betId?: number | string = "10"
) =>
  `/betslip/${trackCode}/Day/${raceNumber}?bettype=${betId}${
    isGreyhound ? "&greyhounds" : ""
  }`;

export const buildBetSlipTalentPick = (pick: TalentPickData) => {
  const { track, race, amount } = pick;
  const betSelections = pick.selections.reduce((accumulator, currentValue) => {
    const raceSelections = currentValue.reduce(
      (selections, value) =>
        selections !== "" ? `${selections},${value}` : value,
      ""
    );
    return accumulator !== ""
      ? `${accumulator};${raceSelections}`
      : raceSelections;
  }, "");

  return `/betslip/${track}/Day/${race}?bettype=${pick.wagerType.id}&betamount=${amount}&betselections=${betSelections}&talentPickId=${track}_${race}&talentPickName=${pick.talent.name}`;
};

export const formatTrackName = (trackName: string = ""): string =>
  trackName
    .trim()
    .replace(/[/]/g, "")
    .replace(/\s/g, "-")
    .replace(/-+/g, "-")
    .toLowerCase();

export const addParamToURL = (
  currentUrl: string,
  paramName: string,
  paramValue: string
) =>
  currentUrl.includes("?")
    ? `${currentUrl}&${paramName}=${paramValue}`
    : `${currentUrl || "/"}?${paramName}=${paramValue}`;

export const buildTalentPicksTVG5Url = (pick: TalentPickData) => {
  const { track, trackName, race, amount, wagerType } = pick;
  const betSelections = pick.selections.reduce(
    (accumulator, currentValue, index) => {
      const raceSelections = currentValue.reduce(
        (selections, value) =>
          selections !== "" ? `${selections},${value}` : `s${index}=${value}`,
        ""
      );

      return accumulator !== ""
        ? `${accumulator}&${raceSelections}`
        : raceSelections;
    },
    ""
  );

  return `/racetracks/${track}/${formatTrackName(
    trackName
  )}?race=${race}&${betSelections}&bet=${amount}&wt=${
    wagerType.abbreviation || wagerType.id
  }`;
};

// TODO: remove greyhounds variation since its only used sometimes.
// and inconsistencies might create navigation issues like Program Page duplication
const buildRaceUrl = (
  trackAbbr: string,
  trackName: string,
  raceNumber: number | string,
  isGreyhound: ?boolean = false
): string =>
  `/${isGreyhound ? "greyhounds" : "racetracks"}/${trackAbbr}/${formatTrackName(
    trackName
  )}?race=${raceNumber}`;

export const buildTalentPickRaceUrl = (
  trackAbbr: string,
  trackName: string,
  raceNumber: number | string
): string =>
  `/talent-picks/program/${trackAbbr}/${formatTrackName(
    trackName
  )}/race-${raceNumber}`;

export const parseSelectionsToString = (
  selections: TalentPickDataRunners[][]
): string =>
  selections
    .map((selection: TalentPickDataRunners[]): string =>
      selection
        .map((sel: TalentPickDataRunners): string => `${sel.order}`)
        .join(",")
    )
    .join("-");

export const parseSelectionsToObject = (selections: string): number[][] => {
  const multiLeg = selections.indexOf("-") > -1;
  const legs = multiLeg ? selections.split("-") : [selections];
  return legs.map((legSelections) => {
    const selectionsSplit = legSelections.split(",");
    return selectionsSplit.map((selection: string): number => +selection);
  });
};

export const buildLiveUrl = (
  trackAbbr: string,
  trackName: string,
  raceNumber: number | string,
  channel: string = "TVG"
): string =>
  `/live/${channel}/${trackAbbr}/${formatTrackName(
    trackName
  )}?race=${raceNumber}`;

export const isFullURL = (url: string): boolean => {
  const pattern = /^https?:\/\//;
  const isFullUrl = pattern.test(url);
  return isFullUrl;
};

export const buildPromoBadgeURL = (
  promoPath: string,
  raceNumber?: string | null = null,
  hasStoryblok?: boolean = false
): string => {
  const promosSlug = without(
    promoPath.replace(/^(\/promos|promos)/, "").split("/"),
    ""
  ).join("/");

  return promoPath
    ? `?${raceNumber ? `race=${raceNumber}&` : ""}promo=${
        hasStoryblok
          ? `promos/${
              promosSlug.charAt(0) === "/" ? promosSlug.substr(1) : promosSlug
            }`
          : promosSlug
      }/#promos`
    : `${raceNumber ? `race=${raceNumber}&` : ""}?promo=/#promos`;
};

export const processURL = (url: string): string => {
  const pattern = /^https?:\/\//;
  const isRelativePath = /^\/.+/.test(url);
  const isFullUrl = pattern.test(url);
  return !isFullUrl && !isRelativePath ? `http://${url}` : url;
};

export default buildRaceUrl;
