"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

const deviceMapped = {
  touch3: {
    iOS: "web_ios",
    AndroidOS: "web_android"
  },
  ios2: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  tvg4: {
    iOS: "desktop",
    AndroidOS: "desktop"
  },
  fdrmobile: {
    iOS: "web_ios",
    AndroidOS: "web_android"
  },
  fdrios: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  fdrandroid: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  androidwrapper: {
    iOS: "iOS",
    AndroidOS: "Android"
  }
};

var _default = (os, product) => (0, _lodash.get)(deviceMapped, `${product}.${os}`, "web_ios");

exports.default = _default;
module.exports = exports.default;