/* eslint-disable global-require */
module.exports = {
  qa: {
    "4njbets": require("./qa/4njbets"),
    iowa: require("./qa/iowa"),
    pabets: require("./qa/pabets"),
    tvg: require("./qa/tvg"),
    fdr: require("./qa/fdr"),
    base: require("./qa/base")
  },
  staging: {
    "4njbets": require("./staging/4njbets"),
    iowa: require("./staging/iowa"),
    pabets: require("./staging/pabets"),
    tvg: require("./staging/tvg"),
    fdr: require("./staging/fdr"),
    base: require("./staging/base")
  },
  production: {
    "4njbets": require("./production/4njbets"),
    iowa: require("./production/iowa"),
    pabets: require("./production/pabets"),
    tvg: require("./production/tvg"),
    fdr: require("./production/fdr"),
    base: require("./production/base")
  }
};
