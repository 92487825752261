import { WagerType } from "@tvg/ts-types/Wager";

type GroupWagerTypeArray = Array<WagerType[]>;

export const groupByWagerType = (betTypes: WagerType[]) => {
  const betTypesFilteredList: GroupWagerTypeArray = [[betTypes[0]]];
  for (let i = 1; i < betTypes.length; i++) {
    let added = false;
    const splitName = betTypes[i as number].name.split(" ");
    for (let j = 0; j < betTypesFilteredList.length; j++) {
      if (splitName[0] === betTypesFilteredList[j as number][0].name) {
        betTypesFilteredList[j].push(betTypes[i]);
        added = true;
      }
    }
    if (!added) {
      betTypesFilteredList.push([betTypes[i]]);
    }
  }
  return betTypesFilteredList;
};
