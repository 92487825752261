// @flow
import { attempt } from "./utils";
import type { Metadata } from "./types.js.flow";

export default (): Metadata | false => {
  let metadata = false;
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.metadata
  ) {
    const data = attempt(() =>
      JSON.parse(decodeURIComponent(window.__TVG_GLOBALS__.METADATA))
    );
    if (!(data instanceof Error)) {
      metadata = data;
    }
  }
  return metadata;
};
