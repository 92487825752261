import React from "react";
import Svg, { Path } from "react-native-svg";
import { colors } from "../../../theming/themes/common";

export const Promo = () => (
  <Svg width="60" height="60" viewBox="0 0 52 52" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.338 1.78882H27.6838C26.6054 1.78882 25.5757 2.23828 24.8424 3.02912L2.81218 26.7883C1.39576 28.3159 1.44057 30.69 2.91362 32.163L19.8387 49.0882C21.3118 50.5612 23.6859 50.606 25.2135 49.1896L48.9727 27.1594C49.7635 26.4261 50.213 25.3964 50.213 24.3179V5.66381C50.213 3.52371 48.4781 1.78882 46.338 1.78882ZM35.685 19.2138C37.2887 19.2138 38.5887 17.9137 38.5887 16.31C38.5887 14.7063 37.2887 13.4062 35.685 13.4062C34.0813 13.4062 32.7812 14.7063 32.7812 16.31C32.7812 17.9137 34.0813 19.2138 35.685 19.2138Z"
      fill={colors.yellow[200]}
      stroke={colors.yellow[800]}
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </Svg>
);
