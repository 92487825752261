import React, { forwardRef } from "react";
import { View, Platform } from "react-native";
import { Svg, Path } from "react-native-svg";
import { useTheme } from "../../theming";
import { useQaLabel } from "../../hooks/useQaLabel";
import { LogoChannelProps } from "./types";

const defaultAspectRatio = 36 / 9;
const isTvg2AspectRatio = 47 / 9;

const computeWidth = (height: number, isTvg2: boolean = false) =>
  isTvg2 ? height * isTvg2AspectRatio : height * defaultAspectRatio;

export const LogoChannel = forwardRef<View, LogoChannelProps>(
  (
    { qaLabel = "channel-logo", isTvg2 = false, height = 9 }: LogoChannelProps,
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const { colors } = useTheme();

    return (
      <View {...viewTestProps} ref={ref}>
        {isTvg2 ? (
          <Svg
            width={computeWidth(height, isTvg2)}
            height={height}
            viewBox="0 0 47 9"
            fill="none"
            accessible={Platform.OS === "web" ? undefined : true}
            accessibilityRole="image"
            accessibilityLabel="TVG2 Channel"
          >
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.444 2.128c-1.668 0-2.9.668-3.344 2.369-.406 1.555.394 2.375 2.424 2.375 1.334 0 2.113-.422 2.569-1.055h-1.685l.498-1.91h5.826l-1.255 4.809h-1.758l-.261-.78C31.196 8.706 29.782 9 27.43 9c-2.87 0-6.633-.579-5.617-4.467.17-.653.433-1.215.765-1.7L17.8 8.715H13.5l-1.828-6.302H8.1L6.455 8.716H2.359l1.645-6.302H0L.555.286h10.499V.285h4.502l.869 5.408h.029L20.146.285h6.704A14.2 14.2 0 0129.767 0c4.559 0 6.458 1.017 6.212 3.183h-4.62c-.002-.436-.236-.707-.576-.855-.352-.153-.804-.2-1.34-.2zm16.094 6.57H36l.04-.26c.15-.952.743-1.434 1.923-2.242.842-.575 1.816-1.021 2.676-1.414 1.339-.612 2.495-1.142 2.67-1.894a.312.312 0 00-.057-.28c-.137-.178-.465-.292-.836-.292-1.176 0-1.585.78-1.72 1.436l-.038.178h-3.33l.068-.28C37.982 1.228 39.862 0 42.984 0c1.727 0 3.013.431 3.618 1.214.376.487.486 1.088.328 1.788-.344 1.511-1.88 2.174-3.364 2.816l-.266.115c-.379.165-.76.32-1.124.467l-.289.117h4.214l-.563 2.18z"
              fill={colors.grey[400]}
            />
          </Svg>
        ) : (
          <Svg
            width={computeWidth(height, isTvg2)}
            height={height}
            viewBox="0 0 36 9"
            fill="none"
            accessible={Platform.OS === "web" ? undefined : true}
            accessibilityRole="image"
            accessibilityLabel="TVG Channel"
          >
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.444 2.128c-1.668 0-2.9.668-3.344 2.369-.406 1.555.394 2.375 2.424 2.375 1.334 0 2.113-.422 2.569-1.055h-1.685l.498-1.91h5.826l-1.255 4.809h-1.758l-.261-.78C31.196 8.706 29.782 9 27.43 9c-2.87 0-6.633-.579-5.617-4.467.17-.653.433-1.215.765-1.7L17.8 8.715H13.5l-1.828-6.302H8.1L6.455 8.716H2.359l1.645-6.302H0L.555.286h10.499V.285h4.502l.869 5.408h.029L20.146.285h6.704A14.2 14.2 0 0129.767 0c4.559 0 6.458 1.017 6.212 3.183h-4.62c-.002-.436-.236-.707-.576-.855-.352-.153-.804-.2-1.34-.2z"
              fill={colors.grey[400]}
            />
          </Svg>
        )}
      </View>
    );
  }
);

export { LogoChannelProps };
