import React from "react";
import Svg from "react-native-svg";
import { useTheme } from "../../theming";
import { useIconSize, useQaLabel } from "../../hooks";
import IconContent from "./iconContent";
import { IconProps, IconNames } from "./types";
import { Container } from "./styled-components";
import { getDefaultColor } from "./utils";
import { useIconList, useIsValidIcon } from "./hooks/index";

export const Icon = React.memo(
  ({
    name,
    size,
    lineColor,
    backgroundColor,
    qaLabel,
    ariaLabel,
    ...props
  }: IconProps) => {
    const { colors } = useTheme();
    const { iconSize, strokeWidth } = useIconSize(size);
    const viewTestProps = useQaLabel(qaLabel || name);
    const iconColor = getDefaultColor(name, colors, {
      line: lineColor,
      background: backgroundColor
    });

    return (
      <Container {...props}>
        <Svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          accessibilityLabel={ariaLabel || name}
          // @ts-ignore
          tabIndex="0"
          {...viewTestProps}
          {...props}
        >
          <IconContent
            iconName={name}
            lineColor={iconColor.line}
            backgroundColor={iconColor.background}
            strokeWidth={strokeWidth}
          />
        </Svg>
      </Container>
    );
  }
);

export { IconNames, useIconList, useIsValidIcon };
export default Icon;
