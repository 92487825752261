import styled, { css } from "styled-components/native";
import { Platform } from "react-native";
import { rgba } from "polished";
import { margin } from "styled-system";

export const StyleViewContainer = styled.View<{ isCondensed: boolean }>`
  ${margin}
  flex-direction: row;
  ${Platform.OS === "web" && `width: auto;`}
  ${({ isCondensed, theme }) =>
    isCondensed &&
    css`
      border-width: 1px;
      border-color: ${theme.colors.blue[100]};
      border-radius: ${theme.radii.xs};
      box-shadow: 0 1px 3px ${rgba(17, 43, 68, 0.12)};
      width: fit-content;
    `}
`;
