import { get, find } from "lodash";
import {
  RaceProgram,
  RaceWagerType,
  BettingInterest
} from "@tvg/ts-types/Race";
import { VisualSelections } from "@tvg/ts-types/Selections";
import formatSequential from "@tvg/formatter/numeric";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

export const simpleBetTypes = [
  WagerTypeCodesEnum.WIN,
  WagerTypeCodesEnum.PLACE,
  WagerTypeCodesEnum.SHOW,
  WagerTypeCodesEnum.WIN_PLACE,
  WagerTypeCodesEnum.WIN_SHOW,
  WagerTypeCodesEnum.PLACE_SHOW,
  WagerTypeCodesEnum.WIN_PLACE_SHOW
];

const setMatrixSizeByColumnCount = (columnCount: number): string[][] =>
  columnCount >= 1 ? Array(columnCount).fill([]) : [[]];

const hasBetType = (
  betTypeID: number | null | undefined,
  currentRace: RaceProgram
): boolean => {
  const wagerTypes = get(currentRace, "wagerTypes", []);
  return !!find(
    wagerTypes,
    (wagerType) => `${wagerType.type.id}` === `${betTypeID}`
  );
};

const getDefaultBetType = (
  currentRace: RaceProgram,
  defaultBetTypeID: string = "",
  logged: boolean = false
): RaceWagerType | null => {
  const wagerTypes = get(currentRace, "wagerTypes", []);
  const defaultType = get(wagerTypes, "[0]");

  if (logged) {
    const findWagerType = find(
      wagerTypes,
      (wagerType) => `${wagerType.type.id}` === `${defaultBetTypeID}`
    );
    return findWagerType || defaultType;
  }

  return defaultType;
};

const isValidBetType = (
  currentRace: RaceProgram,
  betType: number | null | undefined
) => hasBetType(betType, currentRace);

const isValidBetAmount = (
  wager: RaceWagerType | null | undefined,
  betAmount = 0
) => betAmount >= get(wager, "minWagerAmount", 100000);

const isValidBetSelection = (
  wager: RaceWagerType | null | undefined,
  betSelections: string[][]
) => betSelections.length === get(wager, "columnCount", 1);

export const getVisualSelections = (
  selections: Array<Array<string>>,
  bettingInterests?: BettingInterest[],
  isLegSelections: boolean = false
): VisualSelections[] =>
  (selections || []).map((selection, selectionIndex) =>
    (selection || []).flatMap((singleSelection) => {
      const isCurrentRaceSelections = selectionIndex === 0 || !isLegSelections;
      const biRunners: VisualSelections = [];
      const selectedBI = (bettingInterests || []).find(
        (bi) => +bi.biNumber === +singleSelection
      );

      if (selectedBI && isCurrentRaceSelections) {
        (selectedBI.runners || []).forEach((runner) => {
          biRunners.push({
            number: runner.runnerId,
            numberColor: selectedBI.numberColor,
            saddleColor: selectedBI.saddleColor,
            ...(isCurrentRaceSelections
              ? { scratched: !!runner.scratched }
              : {})
          });
        });
      } else {
        biRunners.push({
          number: singleSelection,
          numberColor: "",
          saddleColor: ""
        });
      }

      return biRunners;
    })
  );

export const getSelectionsRunnerNbrScratched = (
  selections: Array<VisualSelections>
): Array<Array<string>> =>
  selections.map((fullSelection) =>
    fullSelection
      .filter((selection) => selection.scratched)
      .map((scratched) => scratched.number)
  );

const getBetAmount = (
  wager: RaceWagerType | null,
  userAccountNumber: string,
  MinimalValues: boolean,
  BetAmountPreference: { [key: string]: number }
) => {
  const picksDefault = [310, 330, 340, 350, 360, 370, 380, 390, 400];
  if (!!userAccountNumber && !MinimalValues) {
    const betTypeId = +get(wager, "type.id", "");
    const betTypeGroupId = get(wager, "group.id", "");

    let defaultValue = 0;

    // Identify picks and daily doubles
    if (picksDefault.includes(betTypeId)) {
      defaultValue = +get(BetAmountPreference, "P", 0);
    } else {
      defaultValue = +get(BetAmountPreference, `["${betTypeGroupId}"]`, 0);
    }

    return defaultValue >= get(wager, "minWagerAmount", 100000)
      ? defaultValue
      : get(wager, "wagerAmounts[0]", 0);
  }
  return get(wager, "wagerAmounts[0]", 0);
};

const getBetTypeFromRace = (
  currentRace: RaceProgram,
  betTypeID: number | null | undefined
): RaceWagerType => {
  const wagerTypes = get(currentRace, "wagerTypes", []);
  const findWagerType = find(
    wagerTypes,
    (wagerType) => `${wagerType.type.id}` === `${betTypeID}`
  );
  return findWagerType || wagerTypes[0];
};

const getOriginalBetTypeFromRace = (
  currentRace: RaceProgram,
  betTypeID?: WagerTypeCodesEnum
): RaceWagerType | undefined => {
  let originalWagerType: RaceWagerType | undefined;

  const searchWagerType = get(currentRace, "wagerTypes", []).find(
    (wt: RaceWagerType) => {
      if (
        wt.type.code !== betTypeID &&
        wt.type.id !== parseInt(`${betTypeID}`, 10)
      ) {
        // original bet type from url ( not grouped)
        originalWagerType = wt.types?.find(
          (wtIn: RaceWagerType) =>
            wtIn.type.code === betTypeID ||
            wtIn.type.id === parseInt(`${betTypeID}`, 10)
        );
        return !!originalWagerType;
      }
      return true;
    }
  );
  return originalWagerType || searchWagerType;
};

export const getWagerTypesNames = (wagerType?: RaceWagerType) => {
  if (!wagerType) {
    return [];
  }

  const {
    isBox,
    columnCount,
    isKey,
    isWheel,
    legCount,
    type: { code }
  } = wagerType;

  if (isKey && isBox) {
    return ["Key", "Box"];
  }

  if (isKey) {
    return ["Key", "Select"];
  }

  if (isBox) {
    return ["Box"];
  }

  if (isWheel) {
    return ["Primary", "Others"];
  }

  if (simpleBetTypes.includes(code) && legCount === 1) {
    return wagerType?.type.name.split("/") || [];
  }
  if ((columnCount === 1 && !isBox) || legCount > 1) {
    return ["Select"];
  }

  return Array.from({ length: columnCount }, (_, i) => formatSequential(i + 1));
};

const betSelections = {
  getDefaultBetType,
  setMatrixSizeByColumnCount,
  hasBetType,
  getBetTypeFromRace,
  getBetAmount,
  isValidBetType,
  isValidBetAmount,
  isValidBetSelection,
  getVisualSelections,
  getSelectionsRunnerNbrScratched,
  getWagerTypesNames,
  getOriginalBetTypeFromRace
};

export default betSelections;
