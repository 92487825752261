import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Success: FC = () => (
  <>
    <Path
      d="M59.0949 31.0108C59.0949 46.5272 46.5164 59.1058 31 59.1058C15.4835
      59.1058 2.90498 46.5272 2.90498 31.0108C2.90498 15.4944 15.4835 2.91583
      31 2.91583C46.5164 2.91583 59.0949 15.4944 59.0949 31.0108Z"
      fill="#CEEBD4"
    />
    <Path
      d="M17.4376 31L27.1251 40.6875L46.5001 21.3125M59.0949 31.0108C59.0949
      46.5272 46.5164 59.1058 31 59.1058C15.4835 59.1058 2.90498 46.5272
      2.90498 31.0108C2.90498 15.4944 15.4835 2.91583 31 2.91583C46.5164
      2.91583 59.0949 15.4944 59.0949 31.0108Z"
      stroke="#329A47"
      strokeWidth="3"
    />
  </>
);
