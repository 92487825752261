"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _base = _interopRequireDefault(require("./base"));

var _brand = require("./brand");

var _default2 = _interopRequireDefault(require("../content/default"));

var _base2 = _interopRequireDefault(require("../content/base"));

var _brand2 = _interopRequireDefault(require("../content/brand"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = env => {
  const confDefault = {
    tvg: _default2.default,
    pabets: _default2.default,
    iowa: _default2.default,
    "4njbets": _default2.default,
    fdr: _default2.default
  };
  const content = {
    production: { ...confDefault
    },
    staging: { ...confDefault
    },
    qa: { ...confDefault
    }
  };
  Object.keys(content).forEach(envName => _brand.BRAND_ALL.map(brand => {
    const baseConf = _base2.default[envName];
    const brandConf = _brand2.default[envName][brand];
    content[envName][brand] = { ...content[envName][brand],
      ...baseConf,
      ...brandConf
    };
    return brand;
  }));

  const config = _brand.BRAND_ALL.reduce((acc, brand) => ({ ...acc,
    [brand]: _base.default.config(content[env][brand])
  }), {});

  return config;
};

exports.default = _default;
module.exports = exports.default;