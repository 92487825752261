// @flow
import styled, { css } from "styled-components";
import { opacityIn, opacityOut } from "../../_static/Transitions";

import buildColor from "../../_static/ColorPalette";
import {
  FromBottomFloating,
  FromBottom,
  FromLeft,
  FromRight,
  FromTop,
  FromFade
} from "./transitions.styled-components";

const defaultLayerOffset = 1050;

export const Overlay = styled.div.attrs((props) =>
  props.qaLabel
    ? {
        "data-qa-label": props.qaLabel
      }
    : {}
)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ layerOffset }) =>
    layerOffset ? defaultLayerOffset + layerOffset : defaultLayerOffset};
  overflow: hidden;
  perspective: 1000px;
  transform-style: preserve-3d;
  background-color: ${(props) =>
    props.isTransparent ? "transparent" : buildColor("black", "70")};
  transition: opacity 0.3s ease-out;
  opacity: ${(props) => +props.opening};

  & ~ div {
    z-index: ${({ layerOffset }) =>
      layerOffset ? defaultLayerOffset + layerOffset : defaultLayerOffset};
  }
`;

export const Transitions = {
  bottomFloating: FromBottomFloating,
  bottom: FromBottom,
  left: FromLeft,
  right: FromRight,
  top: FromTop,
  fade: FromFade
};

export const Container = styled.div.attrs((props) => ({
  "data-qa-label": props.qaLabel
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: ${({ fixedWidth }) => fixedWidth};
  margin: auto;
  min-height: 0;

  & .iframeOnModal {
    top: 48px;
    position: fixed;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-scrolling: touch; /* stylelint-disable-line */
  -webkit-overflow-scrolling: touch; /* stylelint-disable-line */
  background-color: ${(props) =>
    props.isContentTransparent
      ? buildColor("white", "0")
      : buildColor("blue", "100")};
  ${(props) =>
    props.roundedCorners &&
    css`
      border-radius: 0 0 4px 4px;
    `};
  ${(props) =>
    props.contentRoundedCorners &&
    css`
      border-radius: 4px 4px ${props.roundedCorners ? "4px 4px" : "0 0"};
    `};

  & > :first-child {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
`;

export const ContentScroll = styled.div`
  display: flex;
  flex: 1 1 auto;
`;
