"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("./utils");

var _default = () => {
  let metadata = false;

  if (typeof window !== "undefined" && window.__TVG_GLOBALS__ && window.__TVG_GLOBALS__.metadata) {
    const data = (0, _utils.attempt)(() => JSON.parse(decodeURIComponent(window.__TVG_GLOBALS__.METADATA)));

    if (!(data instanceof Error)) {
      metadata = data;
    }
  }

  return metadata;
};

exports.default = _default;
module.exports = exports.default;