// @flow
import type { Env } from "./types.js.flow";
import { isBrowser } from "./utils";

export const AW_ENV_MAPPING = {
  dev: "dev",
  qa: "qa",
  staging: "prod",
  production: "prod"
};

const env: Env[] = ["production", "staging", "qa"];
const defaultEnv: Env = env[0];

const getEnvironmentByHostname = (hostname: ?string): Env => {
  if (hostname === "10.0.2.2") {
    return "qa";
  }

  if (!hostname) {
    return defaultEnv;
  }

  if (/(stage\.|staging\.|cert\.)/gi.test(hostname)) {
    return "staging";
  }

  if (/qa\.|dev\./gi.test(hostname)) {
    return "qa";
  }

  return defaultEnv;
};

const getEnvironmentInBrowser = (): Env | false =>
  !!window.__TVG_GLOBALS__ &&
  !!window.__TVG_GLOBALS__.ENVIRONMENT &&
  window.__TVG_GLOBALS__.ENVIRONMENT;

const getEnvironmentInServer = (): Env | false =>
  // @FIXME in the next line we are doing an explicit typecast, that's why we
  // need the `any` type. However we could try to find how to extend the type of
  // process.env instead.
  typeof process.env.ENVIRONMENT === "string" &&
  (process.env.ENVIRONMENT === "dev"
    ? "qa"
    : env.includes((process.env.ENVIRONMENT: any)) &&
      ((process.env.ENVIRONMENT: any): Env));

/**
 * When fetching an environment two variables have priority:
 *  - in the browser, node-grid should inject a global variable `__TVG_GLOBALS__.ENVIRONMENT`
 *  - in the server, the container has the variable ENVIRONMENT
 * These two variables allows to be deterministic about the environment, and only
 * if by some unknown reason they fail, then we check the hostname as a last resourt
 */
export default (hostname: ?string): Env =>
  isBrowser() && window.__TVG_GLOBALS__
    ? getEnvironmentInBrowser() || getEnvironmentByHostname(hostname)
    : getEnvironmentInServer() || getEnvironmentByHostname(hostname);
