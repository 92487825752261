import styled from "styled-components/native";
import { margin } from "styled-system";
import { View, ScrollView } from "react-native";

export const StyledViewContainer = styled(View)<{ isFullHeight: boolean }>`
  position: relative;
  ${({ isFullHeight }) => isFullHeight && "flex-grow:1"};
  ${margin}
`;

export const ContentView = styled(ScrollView)<{ isFullHeight: boolean }>`
  ${({ isFullHeight }) => isFullHeight && "flex-grow:1"};
`;

export const TabHeaderContainer = styled(View)`
  border-bottom-color: ${({ theme }) => theme.colors.blue[100]};
  border-bottom-width: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[900]};
  z-index: 1;
`;
