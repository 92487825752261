// @flow

import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";
import styled, { css } from "styled-components";
import buildColor from "../ColorPalette";

const getContainerPosition = (device) => {
  switch (device) {
    case "mobile":
      return css`
        overflow: auto;
        padding: 12px 8px 8px 12px;
      `;
    case "tablet":
      return css`
        position: absolute;
        overflow: hidden;
        padding: 12px 12px 8px 4px;
      `;
    default:
      return css`
        height: 160px;
        overflow: hidden;
        padding: 0 8px 8px 0;
      `;
  }
};

const SVGContainer = styled.nav`
  width: 100%;
  background-color: ${buildColor("blue", "900")};
  ${({ device }) => getContainerPosition(device)}
`;

const Rect = styled.rect`
  width: ${({ device }) => (device !== "mobile" ? "calc(20% - 8px)" : "142px")};
`;

const SVG = styled.svg`
  @media (max-width: 1024px) {
    padding-left: 12px;
  }
`;

const G = styled.g`
  transform: ${({ index }) => `translateX(${20 * index}%) translateX(8px)`};

  &:last-child > rect:first-child {
    width: 20%;
  }
`;

type Props = {
  device: Device
};

export default class QuickLinksMask extends PureComponent<Props> {
  static defaultProps = { device: "mobile" };

  renderTabletMask = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="120" width="100%">
      <g fill={`${buildColor("white", "10")}`} fillRule="evenodd">
        <G index={0}>
          <Rect
            device={this.props.device}
            height="120"
            fillRule="nonzero"
            rx="4"
          />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="32"
            transform="translate(0,88)"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </G>
        <G index={1}>
          <Rect
            device={this.props.device}
            height="120"
            fillRule="nonzero"
            rx="4"
          />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="32"
            transform="translate(0,88)"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </G>
        <G index={2}>
          <Rect
            device={this.props.device}
            width="142"
            height="120"
            fillRule="nonzero"
            rx="4"
          />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="32"
            transform="translate(0,88)"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </G>
        <G index={3}>
          <Rect
            device={this.props.device}
            width="142"
            height="120"
            fillRule="nonzero"
            rx="4"
          />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="32"
            transform="translate(0,88)"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </G>
        <G index={4}>
          <Rect
            device={this.props.device}
            height="120"
            fillRule="nonzero"
            rx="4"
          />
          <rect
            fillRule="nonzero"
            width="calc(20%)"
            height="32"
            transform="translate(0,88)"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </G>
      </g>
    </svg>
  );

  renderDesktopMask = () => (
    <SVG xmlns="http://www.w3.org/2000/svg" width="100%" height="152">
      <g fill={`${buildColor("white", "10")}`} fillRule="evenodd">
        <G removeTranslate index={0}>
          <Rect width="190" height="152" fillRule="nonzero" rx="4" />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="40"
            transform="translate(0,112)"
          />
          <rect
            className="pulse"
            width="120"
            height="8"
            x="12"
            y="128"
            rx="1"
          />
        </G>
        <G index={1}>
          <Rect width="191" height="152" fillRule="nonzero" rx="4" />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="40"
            transform="translate(0,112)"
          />
          <rect
            className="pulse"
            width="120"
            height="8"
            x="12"
            y="128"
            rx="1"
          />
        </G>
        <G index={2}>
          <Rect width="190" height="152" fillRule="nonzero" rx="4" />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="40"
            transform="translate(0,112)"
          />
          <rect
            className="pulse"
            width="120"
            height="8"
            x="12"
            y="128"
            rx="1"
          />
        </G>
        <G index={3}>
          <Rect width="191" height="152" fillRule="nonzero" rx="4" />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="40"
            transform="translate(0,112)"
          />
          <rect
            className="pulse"
            width="120"
            height="8"
            x="12"
            y="128"
            rx="1"
          />
        </G>
        <G index={4}>
          <Rect width="191" height="152" fillRule="nonzero" rx="4" />
          <rect
            fillRule="nonzero"
            width="calc(20% - 8px)"
            height="40"
            transform="translate(0,112)"
          />
          <rect
            className="pulse"
            width="120"
            height="8"
            x="12"
            y="128"
            rx="1"
          />
        </G>
      </g>
    </SVG>
  );

  renderMobileMask = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="744" height="120">
      <g fill={`${buildColor("white", "10")}`} fillRule="evenodd">
        <g transform="translate(601)">
          <rect width="143" height="120" fillRule="nonzero" rx="4" />
          <path
            fillRule="nonzero"
            d="M0 88h143v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V88z"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </g>
        <g transform="translate(451)">
          <rect width="142" height="120" fillRule="nonzero" rx="4" />
          <path
            fillRule="nonzero"
            d="M0 88h142v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V88z"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </g>
        <g transform="translate(301)">
          <rect width="142" height="120" fillRule="nonzero" rx="4" />
          <path
            fillRule="nonzero"
            d="M0 88h142v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V88z"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </g>
        <g transform="translate(151)">
          <rect width="142" height="120" fillRule="nonzero" rx="4" />
          <path
            fillRule="nonzero"
            d="M0 88h142v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V88z"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </g>
        <g>
          <rect width="143" height="120" fillRule="nonzero" rx="4" />
          <path
            fillRule="nonzero"
            d="M0 88h143v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V88z"
          />
          <rect className="pulse" width="88" height="8" x="12" y="100" rx="1" />
        </g>
      </g>
    </svg>
  );

  render() {
    const { device } = this.props;
    const mask = () => {
      switch (device) {
        case "desktop":
          return this.renderDesktopMask();
        case "tablet":
          return this.renderTabletMask();
        default:
          return this.renderMobileMask();
      }
    };

    return <SVGContainer device={device}>{mask()}</SVGContainer>;
  }
}
