import React, { useContext, isValidElement } from "react";

import { getAccessibilityRole } from "../../utils/accessibility";
import { useEvents } from "../../hooks/useEvents";
import { useQaLabel } from "../../hooks/useQaLabel";
import {
  SegmentTab,
  SegmentTabContent,
  SegmentText
} from "./styled-components";
import { SCTabProps } from "./types";
import { SCContext } from "./segmentedControl";

export const SegmentedControlTab = ({
  value,
  qaLabel,
  children,
  end
}: SCTabProps): JSX.Element => {
  const { events, focusHandlers } = useEvents({});
  const {
    selectedValue,
    onSelect = () => {},
    isDisabled
  } = useContext(SCContext);

  return (
    <SegmentTab
      {...useQaLabel(qaLabel)}
      accessibilityRole={getAccessibilityRole("tab")}
      disabled={isDisabled}
      onPress={() => onSelect(value)}
      accessibilityState={{
        selected: value === selectedValue,
        disabled: isDisabled
      }}
      {...focusHandlers}
      {...events}
    >
      <SegmentTabContent>
        {isValidElement(children) ? (
          children
        ) : (
          <SegmentText>{children}</SegmentText>
        )}
        {end}
      </SegmentTabContent>
    </SegmentTab>
  );
};
