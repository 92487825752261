import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Warning: FC = () => (
  <>
    <Path
      d="M4.69468 55.2188H57.3025C57.6774 55.2188 57.919 54.8214 57.7464
      54.4886L31.4439 3.76225C31.2574 3.40247 30.7427 3.40246 30.5562
      3.76223L4.25081 54.4886C4.07822 54.8214 4.31976 55.2188 4.69468 55.2188Z"
      fill="#FAD5AF"
    />
    <Path
      d="M31.0001 23.25V38.75M31.0001 42.625V48.4375M30.5562 3.76223L4.25081
      54.4886C4.07822 54.8214 4.31976 55.2188 4.69468 55.2188H57.3025C57.6774
      55.2188 57.919 54.8214 57.7464 54.4886L31.4439 3.76225C31.2574 3.40247
      30.7427 3.40246 30.5562 3.76223Z"
      stroke="#BD6909"
      strokeWidth="3"
    />
  </>
);
