import styled, { css } from "styled-components/native";
import { View } from "react-native";
import { space, color, compose } from "styled-system";
import { FilterCellsProps } from "./types";

const stylesFn = compose(space, color);

const containerSizes = {
  s: css`
    height: 25px;
  `,
  m: css`
    height: 32px;
  `,
  l: css`
    height: 44px;
  `
} as const;

export const FilterCellContainer = styled(View)<
  Pick<FilterCellsProps, "size" | "hasShadow" | "isStretch">
>`
  align-items: center;
  border-radius: 2px;
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${({ theme }) => theme.elevations.low};
    `}
  border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  flex-direction: row;
  ${({ size }) => containerSizes[size as FilterCellsProps["size"]]};
  ${stylesFn}

  @media not all and (-moz-appearance: none) {
    /* CSS properties here will apply to all browsers except Firefox */
    width: ${({ isStretch }) => (isStretch ? "100%" : "max-content")};
  }
`;
