// @flow
// $FlowFixMe
import { useEffect, useState } from "react";

const useAutoFocusOnce = (inputId: string): void => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const input = document.getElementById(inputId);

    if (!initialized && input) {
      input.focus();
      setInitialized(true);
    }
  });
};

export default useAutoFocusOnce;
