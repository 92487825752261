"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const productMap = {
  tvg4: "TVG4",
  tvg5: "TVG5",
  touch2: "Touch",
  touch3: "Touch3",
  ios2: "IOS2",
  ios: "ios",
  exchange: "exchange",
  login: "login",
  fdrmobile: "FDRMOBILE",
  fdrios: "FDRIOS",
  fdriosxsell: "FDRIOS",
  fdrandroid: "FDRANDROID",
  fdrandroidxsell: "FDRANDROID",
  iosnative: "iosnative",
  androidnative: "androidnative",
  androidwrapper: "ANDROIDWRAPPER",
  tvgandroid: "TVGANDROID"
};
const deviceMap = {
  desktop: "Desktop",
  mobile: "Mobile",
  tablet: "Mobile"
};
const brandMap = {
  "4njbets": "4NJBets",
  pabets: "PABets",
  iowa: "IA",
  tvg: "TVG",
  fdr: "FDR"
};

var _default = (brand, product, device) => ({
  brand: brandMap[brand],
  product: productMap[product],
  device: deviceMap[device]
});

exports.default = _default;
module.exports = exports.default;