import React, { useContext } from "react";
import { ThemeProvider } from "styled-components/native";

import { TvgConfContext } from "../../utils/tvgConfProvider";
import { Theme, Devices } from "../types";
import { DESKTOP_THEME, MOBILE_THEME } from "../themes";

export interface TVGThemeProviderProps {
  theme?: Theme;
  device?: Devices;
}

interface DeviceTheme {
  desktop: Theme;
  mobile: Theme;
  tablet: Theme;
}

const DEFAULT_THEME_KEY: "mobile" = "mobile";

const THEME_MAP: DeviceTheme = {
  desktop: DESKTOP_THEME,
  mobile: MOBILE_THEME,
  tablet: MOBILE_THEME
};

export const TVGThemeProvider: React.FC<TVGThemeProviderProps> = ({
  children,
  theme: customTheme,
  device
}) => {
  const { device: deviceInContext } = useContext(TvgConfContext);
  const theme: Theme =
    customTheme ||
    (device && THEME_MAP[device]) ||
    (deviceInContext && THEME_MAP[deviceInContext]) ||
    THEME_MAP[DEFAULT_THEME_KEY];

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default TVGThemeProvider;
