"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = brand => {
  switch (brand) {
    case "4njbets":
      return "nj";

    case "pabets":
      return "pa";

    case "iowa":
      return "ia";

    default:
      return "all";
  }
};

exports.default = _default;
module.exports = exports.default;