import React, { useCallback } from "react";
import { Icon } from "../icon";
import { Saddle } from "../saddle";
import { IconContainer } from "./styled-components";
import { rangeSelections } from "./utils";
import { ShowSelectionsProps } from "./types";
import { useTheme } from "../../theming";

const SaddleNumbers = ({
  numWagerableRunners,
  selections,
  isDark,
  showBetType,
  scratches,
  breed = "thoroughbred",
  onHandicaping
}: ShowSelectionsProps) => {
  const { colors } = useTheme();

  const checkSaddleSelection = useCallback(
    (runnerNumber: string) =>
      selections.filter(
        (saddleSelection) => saddleSelection.number === runnerNumber
      ),
    [selections]
  );

  const hasScratch = scratches ? scratches?.length > 0 : false;

  const isAllSelections =
    numWagerableRunners && Number(numWagerableRunners) === selections.length;

  const isSaddleNumber = !isAllSelections && selections.length > 0;

  const rangedSelections = rangeSelections(selections, scratches)
    .join()
    .split(/(-)/)
    .join()
    .split(",");

  const renderAllSelections = () =>
    isAllSelections ? (
      <Saddle all={isAllSelections} number="All" size="s" breed={breed} />
    ) : (
      // empty Selections
      <Saddle
        number="?"
        size="s"
        saddleColor={colors.white[100]}
        numberColor={colors.white[900]}
        mr="space-1"
      />
    );

  return (
    <>
      {!isSaddleNumber && !hasScratch && !onHandicaping
        ? renderAllSelections()
        : rangedSelections.map((item, index) =>
            item.includes("-") ? (
              <IconContainer key={`sanddle-arrow-${index.toString()}`}>
                <Icon
                  size="m"
                  name="arrowRight"
                  lineColor={isDark && showBetType ? "white.900" : "grey.900"}
                  qaLabel="runner-number-range-arrow"
                  mr="space-1"
                />
              </IconContainer>
            ) : (
              <Saddle
                isScratched={scratches?.includes(item)}
                key={`Saddle-num-${checkSaddleSelection(item)[0].number}`}
                number={checkSaddleSelection(item)[0].number}
                numberColor={checkSaddleSelection(item)[0].numberColor}
                saddleColor={checkSaddleSelection(item)[0].saddleColor}
                all={checkSaddleSelection(item)[0].number === "ALL"}
                breed={breed}
                size="s"
                mr={
                  !showBetType && index === rangedSelections.length
                    ? 0
                    : "space-1"
                }
              />
            )
          )}
    </>
  );
};

export default SaddleNumbers;
