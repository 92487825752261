// @flow
import queryString from "query-string";
import { get } from "lodash";
import type {
  App,
  Product,
  Location,
  Domain,
  Params,
  BrazeDevice
} from "./types.js.flow";

import { isBrowser, isDev } from "./utils";

const microapps: App[] = ["rma", "dma", "prf", "referral"];

export default (
    app: App | Product = "tvg4",
    wrapper: boolean = false,
    callback: string | boolean = false,
    path: string = "",
    params: ?Params = null,
    relative: boolean = false
  ) =>
  (
    domain: Domain,
    location: Location,
    product: Product,
    brazeDevice: BrazeDevice
  ) => {
    let target: string = "";
    let query: string = "";
    const urlParams =
      typeof params === "string" ? queryString.parse(params) : params;

    const localhost = isBrowser()
      ? `http://${get(window, "location.hostname", "").trim()}${
          get(window, "location.port", "").trim()
            ? `:${get(window, "location.port", "").trim()}`
            : ""
        }`
      : `http://localhost:${process ? (process.env.PORT: any) : "8080"}`;

    const pathName = `${path.indexOf("/") === 0 ? "" : "/"}${encodeURI(path)}`;

    switch (app) {
      case "rma":
        target = `https://${domain.desktop}/registration${pathName}`;
        break;
      case "dma":
        target = `https://${domain.desktop}/deposit${pathName}`;
        break;
      case "prf":
        target = `https://${domain.desktop}/tvg-password-recovery/#${
          path ? pathName : "/forgotcredentials"
        }`;
        break;
      case "rgnative":
        target = `https://${domain.desktop}/responsible-gaming-native`;
        break;
      case "promos":
        target = `https://${domain.desktop}/promos${pathName}`;
        break;
      case "referral":
        target = `https://${domain.desktop}/referral${pathName}`;
        break;
      case "talentPicks":
        target = `https://${domain.talentPicks}${encodeURI(path)}`;
        break;
      case "accountWallet":
        target = `https://${
          domain.accountWallet || `account.${domain.mobile}`
        }${pathName}`;
        break;
      case "tvg4":
      case "touch3":
      case "touch2":
      case "ios":
      case "ios2":
        target = isDev()
          ? `${localhost}${pathName}`
          : `https://${domain.mobile}${pathName}`;
        break;
      default:
        target = isDev()
          ? `${localhost}${pathName}`
          : `https://${domain.desktop}${pathName}`;
    }

    if (urlParams && Object.keys(urlParams)) {
      query = Object.keys(urlParams)
        .map((key) => {
          // $FlowFixMe
          const value = urlParams[key] || "";
          // $FlowFixMe
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");
    }

    let url = relative ? pathName : target;

    if (microapps.includes(app)) {
      url = `${target}?location=${location}&product=${product}${
        wrapper ? "&wrapper=true" : ""
      }&device=${brazeDevice}`;

      if (callback) {
        url = `${url}&callback=`;
        if (typeof callback === "string") {
          url = `${url}${encodeURIComponent(callback)}`;
        } else if (
          typeof callback === "boolean" &&
          typeof document === "object"
        ) {
          url = `${url}${encodeURIComponent(
            `${document.location.host}${
              document.location.pathname !== "/"
                ? document.location.pathname
                : ""
            }${document.location.search}`
          )}`;
        } else {
          url = `${url}${encodeURIComponent(domain.desktop)}`;
        }
      }
      url = `${url}${query ? "&" : ""}`;
    } else {
      url = `${url}${query ? "?" : ""}`;
    }

    return `${url}${query}`;
  };
