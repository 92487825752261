"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("./utils");

var _default = {
  config(envConfig) {
    const serviceHost = (0, _utils.getServiceHost)(envConfig);
    const service = {};
    const servicePort = 8080;
    Object.keys(envConfig.service).forEach(key => {
      const serviceName = envConfig.serviceName[key];
      const servicePath = envConfig.service[key];
      service[key] = (0, _utils.isBrowser)() || (0, _utils.isRN)() ? `${serviceHost}${servicePath}` : `http://${serviceName}:${servicePort}${servicePath}`;
    });
    return {
      service_host: serviceHost,
      service,
      google: envConfig.google,
      amplitude: envConfig.amplitude,
      domain: {
        desktop: envConfig.domain.desktop,
        mobile: envConfig.domain.mobile,
        talentPicks: envConfig.domain.talentPicks,
        accountWallet: envConfig.domain.accountWallet
      },
      externalDomain: envConfig.externalDomain,
      externalServices: envConfig.externalServices,
      clientId: envConfig.clientId,
      brazeId: envConfig.brazeId,
      perimeterxId: envConfig.perimeterxId,
      paypalID: envConfig.paypalID,
      apptentiveID: envConfig.apptentiveID,
      storyblokToken: envConfig.storyblokToken,
      storyblokVersion: envConfig.storyblokVersion,
      placesAPI: envConfig.placesAPI,
      sonarQube: envConfig.sonarQube,
      dataDogRum: envConfig.dataDogRum,
      siftAccountId: envConfig.siftAccountId,
      siftApiKey: envConfig.siftApiKey,
      siftBeaconKey: envConfig.siftBeaconKey,
      appTentiveIos: envConfig.appTentiveIos,
      appTentiveAndroid: envConfig.appTentiveAndroid,
      appTentiveWeb: envConfig.appTentiveWeb
    };
  }

};
exports.default = _default;
module.exports = exports.default;