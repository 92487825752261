"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getServiceHost = exports.isDev = exports.isRN = exports.isBrowser = exports.attempt = void 0;

var _lodash = require("lodash");

const attempt = fn => {
  let result;

  try {
    result = fn();
  } catch (e) {
    result = e;
  }

  return result;
};

exports.attempt = attempt;

const isBrowser = () => typeof window !== "undefined";

exports.isBrowser = isBrowser;

const isRN = () => typeof navigator !== "undefined" && navigator.product === "ReactNative";

exports.isRN = isRN;

const isDev = () => isBrowser() && typeof (0, _lodash.get)(window, "location.hostname.contains") === "function" && window.location.hostname.contains("local") || typeof process.env.ENVIRONMENT === "string" && process.env.ENVIRONMENT === "dev";

exports.isDev = isDev;

const getServiceHost = envConfig => isBrowser() ? `//${envConfig.service_host}` : `http://${envConfig.service_host}`;

exports.getServiceHost = getServiceHost;