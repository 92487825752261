"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BRAND_ALL = exports.BRAND_IOWA = exports.BRAND_PABETS = exports.BRAND_4NGBETS = exports.BRAND_FDR = exports.BRAND_TVG = void 0;
const BRAND_TVG = "tvg";
exports.BRAND_TVG = BRAND_TVG;
const BRAND_FDR = "fdr";
exports.BRAND_FDR = BRAND_FDR;
const BRAND_4NGBETS = "4njbets";
exports.BRAND_4NGBETS = BRAND_4NGBETS;
const BRAND_PABETS = "pabets";
exports.BRAND_PABETS = BRAND_PABETS;
const BRAND_IOWA = "iowa";
exports.BRAND_IOWA = BRAND_IOWA;
const BRAND_ALL = [BRAND_TVG, BRAND_FDR, BRAND_4NGBETS, BRAND_PABETS, BRAND_IOWA];
exports.BRAND_ALL = BRAND_ALL;

var _default = hostname => {
  if (hostname) {
    if (/(nj|4njbets)(\.|-)/gi.test(hostname)) {
      return BRAND_4NGBETS;
    }

    if (/(pa|pabets)(\.|-)/gi.test(hostname)) {
      return BRAND_PABETS;
    }

    if (/ia(\.|-)/gi.test(hostname)) {
      return BRAND_IOWA;
    }

    if (/fdbox|fanduel(\.|-)|racing/gi.test(hostname)) {
      return BRAND_FDR;
    }
  }

  return BRAND_TVG;
};

exports.default = _default;