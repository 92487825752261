import React from "react";
import { useTheme } from "../../theming";
import { Icon } from "../icon";
import { IconButtonProps } from "./types";
import { StyledButton, Container } from "./styled-components";
import Tooltip from "../tooltip";
import { useEvents } from "../../hooks/useEvents";

export const IconButton = ({
  variant,
  size = "l",
  iconName = "arrowLeft",
  placementTooltip = "bottom-mid",
  tertiaryColor = "grey.900",
  textTooltip = "",
  qaLabel,
  onPress,
  ...rest
}: IconButtonProps) => {
  const { iconButtons, colors } = useTheme();
  const { events, hoverHandlers, pressHandlers, focusHandlers } = useEvents({});

  const containerIconButton = () => (
    <StyledButton
      variant={variant}
      size={size}
      textTooltip={textTooltip || undefined}
      tertiaryColor={tertiaryColor}
      hoveredColor={getTertiaryColor(tertiaryColor, true, false, true)}
      pressedColor={getTertiaryColor(tertiaryColor, false, true, true)}
      disabled={rest.isDisabled}
      activeOpacity={1}
      underlayColor={
        variant !== "tertiary"
          ? iconButtons[variant]?.pressed?.background
          : getTertiaryColor(tertiaryColor, false, true, true)
      }
      onPress={onPress}
      qaLabel={qaLabel}
      {...rest}
      {...events}
      {...hoverHandlers}
      {...pressHandlers}
      {...focusHandlers}
    >
      <Icon
        name={iconName}
        size="m"
        lineColor={
          variant !== "tertiary"
            ? iconButtons[variant]?.default?.color
            : getTertiaryColor(tertiaryColor, false, false)
        }
        backgroundColor="transparent"
        qaLabel="button-icon"
      />
    </StyledButton>
  );

  const getTertiaryColor = (
    colorValue: string,
    isHovered: boolean,
    isPressed: boolean,
    isMobile?: boolean
  ) => {
    const [color, variantColor] = colorValue.split(".");
    // @ts-ignore
    const getColor = colors[color];
    let setColor;

    if (isMobile) return null;

    if (isHovered) setColor = getColor[100];

    if (isPressed) setColor = getColor[200];

    return setColor ?? getColor[variantColor];
  };

  return (
    <Container>
      {textTooltip ? (
        <Tooltip
          qaLabel="tooltip"
          content={textTooltip}
          placement={placementTooltip}
          isDarkMode={
            variant === "secondary_dark" || variant === "tertiary_dark"
          }
          isDisabled={rest.isDisabled}
        >
          {containerIconButton()}
        </Tooltip>
      ) : (
        containerIconButton()
      )}
    </Container>
  );
};

export { IconButtonProps };
export default IconButton;
