import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Info: FC = () => (
  <>
    <Path
      d="M59.0949 31.0108C59.0949 46.5272 46.5164 59.1058 31 59.1058C15.4835
      59.1058 2.90497 46.5272 2.90497 31.0108C2.90497 15.4944 15.4835 2.91583
      31 2.91583C46.5164 2.91583 59.0949 15.4944 59.0949 31.0108Z"
      fill="#CDDFFA"
    />
    <Path
      d="M30.9999 46.5V27.125M30.9999 21.3125V15.5M59.0949 31.0108C59.0949
      46.5272 46.5164 59.1058 31 59.1058C15.4835 59.1058 2.90497 46.5272
      2.90497 31.0108C2.90497 15.4944 15.4835 2.91583 31 2.91583C46.5164
      2.91583 59.0949 15.4944 59.0949 31.0108Z"
      stroke="#3574D4"
      strokeWidth="3"
    />
  </>
);
