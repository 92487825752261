// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("blue", "000")};
  }

  50% {
    fill: ${color("blue", "100")};
  }

  100% {
    fill: ${color("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 57px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class IppMatchConditionsMask extends PureComponent<*> {
  render() {
    return (
      <div data-qa-label="ippMatchConditionsMask">
        <SVG>
          <rect width="100%" height="57" fill="#fff" rx=".5" />
          <rect
            className="pulse"
            width="226"
            height="12"
            x="12"
            y="15"
            fill={color("blue", "000")}
            rx="1"
          />
          <rect
            className="pulse"
            width="179"
            height="9"
            x="12"
            y="35"
            fill={color("blue", "000")}
            rx="1"
          />
        </SVG>
      </div>
    );
  }
}
