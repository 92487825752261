import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Promo: FC = () => (
  <Path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M51.338 6.78882H32.6839C31.6054 6.78882 30.5757 7.23828 29.8424
    8.02912L7.81223 31.7883C6.39581 33.3159 6.44062 35.69 7.91367 37.163L24.8388
    54.0882C26.3118 55.5612 28.6859 55.606 30.2135 54.1896L53.9727
    32.1594C54.7635 31.4261 55.213 30.3964 55.213 29.3179V10.6638C55.213 8.52371
    53.4781 6.78882 51.338 6.78882ZM40.685 24.2138C42.2887 24.2138 43.5888
    22.9137 43.5888 21.31C43.5888 19.7063 42.2887 18.4062 40.685 18.4062C39.0813
    18.4062 37.7812 19.7063 37.7812 21.31C37.7812 22.9137 39.0813 24.2138 40.685
    24.2138Z"
    fill="#FFE099"
    stroke="#A87707"
    strokeWidth="3"
    strokeLinejoin="round"
  />
);
