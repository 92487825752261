// @flow
import { get } from "lodash";
import MobileDetect from "mobile-detect";

import type { TvgConf, Product } from "./types.js.flow";
import tvgBrand, { BRAND_FDR } from "./brand";
import tvgEnvironment from "./environment";
import content from "./content";
import tvgDevice from "./device";
import getBrazeDevice from "./brazeDevice";
import tvgProduct, {
  PRODUCTS_IOS,
  PRODUCTS_FDR,
  PRODUCTS_FDR_X_SELL
} from "./product";
import tvgLocation from "./location";
import tvgFeatures from "./features";
import tvgMessages from "./messages";
import tvgMetadata from "./metadata";
import buildUrl from "./buildurl";
import states from "./states";
import getGraphContext from "./graphContext";
import { isBrowser, isRN as isRNFunc } from "./utils";

const OS_IOS = "iOS";

let hostname;
let userAgent;
let setProduct = "tvg4";

// appConf
// appConf is set on the react native app and contains the necessary info
// to instatiate tvgConf

export default (
  givenHostname: ?string = hostname,
  givenUserAgent: ?string = userAgent,
  givenProduct: ?Product = setProduct
): TvgConf => {
  if (!givenHostname && typeof window !== "undefined" && window.location) {
    hostname = window.location.hostname;
  } else {
    hostname = get(global, "appConf.hostname", null) || givenHostname;
  }

  if (!givenUserAgent && typeof window !== "undefined" && window.navigator) {
    userAgent = window.navigator.userAgent;
  } else {
    userAgent = get(global, "appConf.userAgent", null) || givenUserAgent;
  }

  setProduct =
    get(global, "appConf.product", null) || givenProduct || setProduct;

  const uaInfo = new MobileDetect(userAgent);

  const environment =
    get(global, "appConf.environment", null) || tvgEnvironment(hostname);
  const product = tvgProduct(setProduct);
  const device =
    get(global, "appConf.device", null) || tvgDevice(userAgent, product);
  const os = PRODUCTS_IOS.includes(product) ? OS_IOS : uaInfo.os(userAgent);
  const features = tvgFeatures.readFeatures();
  const messages = tvgMessages.readMessages();
  const metadata = tvgMetadata();
  const configs = content(environment);
  const brand = PRODUCTS_FDR.includes(product) ? BRAND_FDR : tvgBrand(hostname);
  const brandConfig = configs[brand];
  const location = tvgLocation(brand);
  const isRN = isRNFunc();
  const gaContext = `google.${
    product === "androidwrapper" && brand === "fdr"
      ? "fdrandroidwrapper"
      : product
  }`;

  const amplitudeContext = `amplitude.${product}`;
  const brazeDevice = getBrazeDevice(os, product);
  return {
    brand,
    device,
    environment,
    product,
    features,
    messages,
    metadata,
    os,
    brazeDevice,
    context: (productContext = product, forceStandalone = false) =>
      `${
        forceStandalone && PRODUCTS_FDR_X_SELL.includes(productContext)
          ? productContext.replace("xsell", "")
          : productContext
      }-${PRODUCTS_FDR.includes(productContext) ? BRAND_FDR : brand}`,
    config: (configPath) => get(brandConfig, configPath, brandConfig),
    gaConfig: () => get(brandConfig, gaContext, brandConfig),
    amplitudeConfig: () => get(brandConfig, amplitudeContext, brandConfig),
    getFeatures: (localDevice = device, serverSide = false) =>
      tvgFeatures.getFeatures(
        brandConfig.service,
        // $FlowFixMe
        PRODUCTS_FDR_X_SELL.includes(product)
          ? product.replace("xsell", "")
          : product,
        localDevice,
        brand,
        serverSide
      ),
    getMessages: (namespaces, forceFetch = false) =>
      tvgMessages.getMessages(
        namespaces,
        brandConfig.service.capi,
        // $FlowFixMe
        PRODUCTS_FDR_X_SELL.includes(product)
          ? product.replace("xsell", "")
          : product,
        device,
        brand,
        forceFetch
      ),
    getStates: () => states,
    getDomains: (name = "desktop") => {
      const brandMap = Object.keys(configs);
      return brandMap.map((configBrand) => configs[configBrand].domain[name]);
    },
    getClientId: () =>
      isBrowser()
        ? btoa(`${brandConfig.clientId}:`)
        : Buffer.from(`${brandConfig.clientId}:`).toString("base64"),
    getExternalDomain: () => get(brandConfig, "externalDomain", {}),
    buildUrl: (options) => {
      const {
        app = product,
        wrapper = false,
        callback = false,
        path = "",
        params = null,
        relative = false
      } = options;
      return buildUrl(
        app,
        wrapper,
        callback,
        path,
        params,
        relative
      )(
        brandConfig.domain,
        brand === BRAND_FDR ? BRAND_FDR : location,
        product,
        brazeDevice
      );
    },
    graphContext: () => getGraphContext(brand, product, device),
    isRN,
    isBrowser
  };
};
