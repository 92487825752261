"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildRedirectMessage = exports.errorParser = exports.TimeDiffService = exports.tvgHeaders = void 0;

var _conf = _interopRequireDefault(require("@tvg/conf"));

var _reactCookie = _interopRequireDefault(require("react-cookie"));

var _static = require("./static");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": (0, _conf.default)().context(),
  "x-requested-with": "XMLHttpRequest"
});

exports.tvgHeaders = tvgHeaders;
const timeDiff = [];
const TimeDiffService = {
  markStart: mark => {
    timeDiff[mark] = Date.now();
  },
  markEnd: mark => Date.now() - timeDiff[mark],
  getMark: mark => timeDiff[mark]
};
exports.TimeDiffService = TimeDiffService;

const errorParser = error => error in _static.errors ? _static.errors[error] : "Fail to login.";

exports.errorParser = errorParser;

const buildRedirectMessage = (redirectUrl, capiRedirectMessage) => {
  const capiMessage = JSON.parse(capiRedirectMessage);
  const {
    loginRedirectionTitle = "<brand> CUSTOMER",
    loginRedirectionMessage = "Looks like you’re from <state>! Due to state regulation, we need to redirect you to <brand>"
  } = capiMessage;
  let brand = "tvg";

  if (redirectUrl.indexOf("4njbets") > -1) {
    brand = "4njbets";
  } else if (redirectUrl.indexOf("ia-") > -1 || redirectUrl.indexOf("ia.tvg") > -1) {
    brand = "iowa";
  } else if (redirectUrl.indexOf("pabets") > -1) {
    brand = "pabets";
  }

  const stateMap = {
    "4njbets": {
      brand: "4NJBets",
      state: "New Jersey"
    },
    iowa: {
      brand: "TVG - IA",
      state: "Iowa"
    },
    pabets: {
      brand: "PABets",
      state: "Pennsylvania"
    },
    tvg: {
      brand: "TVG",
      state: "TVG"
    }
  };
  return {
    redirectMessage: loginRedirectionMessage.replace(/<state>/g, stateMap[brand].state).replace(/<brand>/g, stateMap[brand].brand),
    redirectTitle: loginRedirectionTitle.replace(/<brand>/g, stateMap[brand].brand)
  };
};

exports.buildRedirectMessage = buildRedirectMessage;