// @flow
import React, { type ComponentType } from "react";
import { ApolloProvider } from "@apollo/client";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ResponsibleGaming from "@tvg/gtm/src/modules/ResponsibleGaming";
import ApolloContext from "@tvg/utils/apolloContext";
import tvgConf from "@tvg/conf/cjs/index";
import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./configureStore";
import Main from "./pages";

const preloadedState = window.__RG_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
window.rgRendered = true;

window.handleNativeMessages = (type, body) => {
  const message = {};
  const postMessage =
    window &&
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage;
  if (postMessage) {
    message.type = type;
    if (body) {
      message.body = body;
    }
    postMessage(JSON.stringify(message));
  }
};

// random gui from the net...
const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

// testing new RN message system
window.promiseChain = Promise.resolve();
window.callbacks = {};

window.nativeBridge = (type, data, success, error) => {
  const msgObj = {
    type,
    body: data || {},
    msgId: ""
  };

  if (success || error) {
    msgObj.msgId = guid();
  }

  const msg = JSON.stringify(msgObj);

  window.promiseChain = window.promiseChain
    .then(
      () =>
        new Promise((resolve) => {
          if (msgObj.msgId !== "") {
            window.callbacks[msgObj.msgId] = {
              onsuccess: success,
              onerror: error
            };
          }
          window.ReactNativeWebView.postMessage(msg);

          resolve();
        })
    )
    .catch((e) => {
      console.error(`nativeBridge promise failed ${e.message}`);
    });
};

ResponsibleGaming();
const store = configureStore(preloadedState);
// gets conf for specific host / device
tvgConf(window.location.hostname, window.navigator.userAgent);

const hotRender = (App: ComponentType<*>) =>
  document.getElementById("responsibleGaming") &&
  render(
    <ApolloProvider client={ApolloClient.createClient(false)}>
      <ApolloContext.Provider
        value={{
          fcpClient: ApolloClient.createClient(false, "fcp"),
          rdaClient: ApolloClient.createClient(false, "rda")
        }}
      >
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloContext.Provider>
    </ApolloProvider>,
    // $FlowFixMe
    document.getElementById("responsibleGaming")
  );

export default hotRender(Main);
