import styled, { css } from "styled-components/native";
import { compose, variant } from "../../../../theming/styledSystem";
import { stylesStepBarUncompleted } from "../styles";
import { ProgressBarVariant } from "../../types";

const stylesStepBarUncompletedText = compose(
  variant({
    prop: "variant",
    variants: {
      dark: {
        color: "blue.800"
      },
      light: {
        color: "blue.300"
      }
    }
  })
);

export const StyledAchievementStepContainer = styled.View<{
  isCompleted?: boolean;
  variant: ProgressBarVariant;
  isMobile: boolean;
}>`
  display: flex;
  height: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
  width: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
  border-radius: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  ${({ theme, isCompleted }) =>
    isCompleted
      ? css`
          background-color: ${theme.colors.green[500]};
          color: ${theme.colors.white[900]};
        `
      : css`
          background-color: ${theme.colors.blue[100]};
          ${stylesStepBarUncompleted}
        `}
`;

export const StyledAchievementStepText = styled.Text<{
  isCompleted?: boolean;
  variant: ProgressBarVariant;
  isMobile: boolean;
}>`
  ${({ theme, isMobile }) => css`
    font-family: ${theme.fonts.regular};
    font-size: ${isMobile ? theme.fontSizes.s : theme.fontSizes.l};
    font-weight: ${theme.fontWeights.bold};
  `}

  ${({ theme, isCompleted }) =>
    isCompleted
      ? css`
          color: ${theme.colors.white[900]};
        `
      : stylesStepBarUncompletedText}
`;
