import type {
  ColorProps,
  SpaceProps,
  BackgroundProps,
  BorderRadiusProps
} from "styled-system";

import { Placement as PopperPlacement } from "../popper";

export { PopperPlacement };

export interface TooltipProps {
  /**
   * Set tooltip render position
   * @default top-mid
   */
  placement?: PopperPlacement;
  /**
   * Set tooltip content to be rendered
   */
  content: string;
  /**
   * Set tooltip content color scheme
   */
  isDarkMode?: boolean;
  /**
   * Custom data-qa-label
   * @default false
   */
  qaLabel?: string;
  /**
   * Disable Tooltip interaction
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Prop from Icon Button Component
   * @default false
   */
  isIconButton?: boolean;
  /**
   * Component children (react element)
   */
  children: JSX.Element;
  /**
   * Set z-index of overlay element
   */
  layer?: number;
}

export interface TooltipContentProps
  extends ColorProps,
    SpaceProps,
    BackgroundProps,
    BorderRadiusProps {
  qaLabel?: string;
  isIconButton?: boolean;
  variant: "dark" | "light";
  placement: PopperPlacement;
}
