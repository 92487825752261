import { Theme } from "./types";

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

export { default, TVGThemeProvider } from "./components/themeProvider";
// styled-components utils
export { default as themeGet } from "./utils/themeGet";
// theme hooks
export { default as useTheme } from "./hooks/useTheme";
export { useColor } from "./hooks/useColor";
// themes
export { DESKTOP_THEME, MOBILE_THEME } from "./themes";
// types
export * from "./types";
