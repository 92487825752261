import { SpaceProps } from "../../theming/styledSystem";
import { IconSizeVariants as IconSizes } from "../../theming/types";
import { IconNames } from "../../_static/icons/types";

export interface IconProps extends SpaceProps {
  name: IconNames;
  size: IconSizes;
  lineColor?: string;
  backgroundColor?: string;
  qaLabel?: string;
  ariaLabel?: string;
}

export interface IconContentProps {
  iconName: IconNames;
  lineColor: string;
  backgroundColor: string;
  strokeWidth: string;
}

export { IconNames, IconSizes };
