import styled from "styled-components/native";
import { View } from "react-native";

export const StyledView = styled(View)`
  background-color: pink;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
