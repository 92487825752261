import styled, { css } from "styled-components/native";
import { rgba } from "polished";
import { TouchableHighlight, Text } from "react-native";
import { EventsProps } from "./types";

export const StyledTouchableHighlight = styled(TouchableHighlight)<EventsProps>`
  height: 32px;
  align-self: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white[900]};
  padding-left: ${({ theme, isCondensed }) =>
    isCondensed ? 10 : theme.space["space-5"]};
  padding-right: ${({ theme, isCondensed }) =>
    isCondensed ? 10 : theme.space["space-5"]};
  ${({ isHovered, isPressed, isSelected, theme }) =>
    (isHovered || isSelected) &&
    css`
      background-color: ${isPressed
        ? theme.colors.blue_accent[200]
        : theme.colors.blue_accent["000"]};
    `}
  ${({ isCondensed, theme }) =>
    !isCondensed &&
    css`
      box-shadow: 0 1px 3px ${rgba(17, 43, 68, 0.12)};
      margin-right: 8px;
      height: 44px;
      border-width: 1px;
      border-radius: ${theme.radii.xs};
      border-color: ${theme.colors.blue[100]};
    `}
  ${({ isHovered, isCondensed }) =>
    isHovered &&
    !isCondensed &&
    css`
      box-shadow: 0 2px 4px ${rgba(17, 43, 68, 0.14)};
    `}
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.blue_accent[100]};
      border-color: ${theme.colors.blue_accent[500]};
      box-shadow: none;
    `};
  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      border-color: ${theme.colors.blue[100]};
      background-color: ${theme.colors.blue_accent[200]};
      box-shadow: none;
    `}
  ${({ isFocused }) =>
    isFocused &&
    css`
      outline-width: medium;
      outline-offset: -1.5px;
      outline-style: auto;
    `}
`;

export const StyledText = styled(Text)<EventsProps>`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grey[900]};
  ${({ isHovered, theme }) =>
    isHovered &&
    css`
      color: ${theme.colors.grey[900]};
    `}
  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      color: ${theme.colors.grey[900]};
    `}
  ${({ isSelected, isPressed, theme }) =>
    isSelected &&
    !isPressed &&
    css`
      color: ${theme.colors.blue_accent[700]};
    `}
`;
