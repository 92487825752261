import React, { useMemo } from "react";
import { Selections } from "./styled-components";
import { ShowSelectionsProps, SaddleSelectionsProps } from "./types";
import SaddleNumbers from "./saddleNumbers";
import { useQaLabel } from "../../hooks";

export const SaddleSelections = ({
  numWagerableRunners,
  selections,
  isDark,
  showBetType,
  scratches,
  breed = "thoroughbred",
  qaLabel = "saddle-selections-container",
  onHandicaping
}: ShowSelectionsProps) => {
  const viewTestProps = useQaLabel(qaLabel || "saddle-selections-container");
  const betSelections = useMemo(
    () =>
      selections?.length > 0
        ? selections.sort(
            (a, b) =>
              parseInt(a.number.toString(), 10) -
              parseInt(b.number.toString(), 10)
          )
        : [],
    [selections]
  );

  return (
    <Selections {...viewTestProps}>
      <SaddleNumbers
        scratches={scratches}
        numWagerableRunners={numWagerableRunners}
        selections={betSelections}
        isDark={isDark}
        showBetType={showBetType}
        breed={breed}
        onHandicaping={onHandicaping}
      />
    </Selections>
  );
};

export default SaddleSelections;
export type { SaddleSelectionsProps };
