import React, { FC, memo } from "react";
import { get } from "lodash";
import { useQaLabel } from "../../hooks";
import { useTheme } from "../../theming";
import { StyledSaddle, StyledParagraph } from "./styled-components";

import {
  SaddleProps,
  Breed,
  HarnessVariation,
  ThoroughbredVariation,
  SaddleColor
} from "./types";
import { SADDLE_COLORS } from "./consts";

const getRunnerColors = (
  runnerNumber: number | string,
  breed: Breed = "thoroughbred"
): SaddleColor => {
  const runnerNumberInt =
    typeof runnerNumber === "string"
      ? parseInt(runnerNumber, 10)
      : runnerNumber;

  const breedFilter = get(SADDLE_COLORS, `[${breed}]`) ? breed : "thoroughbred";

  const { numberColor, saddleColor } = get(
    SADDLE_COLORS,
    `[${breedFilter}][${runnerNumberInt}]`,
    get(
      SADDLE_COLORS,
      breedFilter === "harness"
        ? `[${breedFilter}][15]`
        : `[${breedFilter}][20]`
    )
  );

  return {
    numberColor,
    saddleColor
  };
};

export const Saddle: FC<SaddleProps> = memo(
  ({
    all,
    number,
    qaLabel,
    numberColor,
    saddleColor,
    fullHeight,
    size,
    breed = "thoroughbred",
    ...rest
  }) => {
    const { colors } = useTheme();
    const viewTestProps = useQaLabel(qaLabel || `saddle-container-${number}`);
    const saddleNumber =
      typeof number === "number" ? number.toString() : number;

    const defaultColors = getRunnerColors(number, breed);
    const runnerColors: SaddleColor = {
      numberColor: numberColor || defaultColors.numberColor,
      saddleColor: saddleColor || defaultColors.saddleColor
    };

    return (
      <StyledSaddle
        {...viewTestProps}
        all={all}
        saddleColor={all ? colors.blue["100"] : runnerColors.saddleColor}
        fullHeight={fullHeight}
        size={size}
        {...rest}
      >
        <StyledParagraph
          fontFamily="bold"
          fontSize="s"
          color={all ? colors.black["900"] : runnerColors.numberColor}
          textTransform={all ? "capitalize" : "none"}
          qaLabel="saddle-number"
          size={size}
        >
          {all ? saddleNumber.toLowerCase() : saddleNumber}
        </StyledParagraph>
      </StyledSaddle>
    );
  }
);

export default Saddle;
export { SADDLE_COLORS, getRunnerColors };
export type { SaddleProps, Breed, HarnessVariation, ThoroughbredVariation };
