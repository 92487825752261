import styled from "styled-components/native";
import { View } from "react-native";
import { colors } from "../../../theming/themes/common/colors";
import { Header, Paragraph } from "../../typography";

export const StyledComponent = styled(View)<{
  hasRoundedCorners: boolean;
  hasBorderBottom?: boolean;
  hasShadow: boolean;
  subtitle: string;
  isMobile: boolean;
}>`
  flex-direction: row;
  align-content: space-between;
  padding: ${({ isMobile, theme }) =>
    isMobile ? theme.space["space-3"] : "14px"};
  background-color: ${colors.white["900"]};
  border-bottom-color: ${colors.blue["100"]};
  ${({ hasBorderBottom }) =>
    hasBorderBottom ? "border-bottom-width: 1px;" : "border-bottom-width: 0px;"}
  ${({ hasShadow }) =>
    hasShadow && `box-shadow: 0 1px 3px ${colors.blue["100"]}`};
  ${({ subtitle, isMobile }) =>
    isMobile
      ? `min-height: ${subtitle ? "69px" : "48px"}`
      : `min-height: ${subtitle ? "85px" : "64px"}`};
  ${({ theme, hasRoundedCorners }) =>
    hasRoundedCorners &&
    `
    border-top-left-radius: ${theme.radii.s};
    border-top-right-radius: ${theme.radii.s}`}
`;

export const StyledHeader = styled(Header)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.lineHeights.l};
`;

export const TextContainer = styled(View)<{
  subtitle: string;
  isMobile: boolean;
  isPaddingLeft: boolean;
  isPaddingRight: boolean;
  withoutButton: boolean;
}>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${({ isMobile, theme, isPaddingLeft, isPaddingRight }) =>
    isMobile
      ? `${theme.space["space-1"]}
      ${isPaddingRight ? theme.space["space-8"] : "0"}
      ${theme.space["space-1"]}
      ${isPaddingLeft ? theme.space["space-8"] : "0"}`
      : `${theme.space["space-2"]}
         ${isPaddingRight ? theme.space["space-8"] : "0"}
         ${theme.space["space-2"]}
         ${isPaddingLeft ? theme.space["space-8"] : "0"}`};
  ${({ withoutButton, isMobile, theme }) =>
    withoutButton &&
    `padding: ${isMobile ? theme.space["space-1"] : theme.space["space-2"]}`};
`;

export const DescriptionContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.space["space-1"]};
`;

export const ButtonIconContainer = styled(View)`
  padding: 2px 0;
`;

export const ContainerLink = styled(View)`
  flex-shrink: 1;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export const LeftElement = styled(View)`
  justify-content: center;
`;
