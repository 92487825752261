import styled from "styled-components/native";
import { View } from "react-native";
import { childrenSpacing } from "../../styled-components";

export const Page = styled(View)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.white[900]};
  ${childrenSpacing}
`;
