// @flow
// $FlowFixMe
import React, { useMemo, useState, useEffect } from "react";
import { attempt, get, noop } from "lodash";
import { connect, useSelector } from "react-redux";
import type { Device } from "@tvg/types/Device";
import type { NullaryFn, UnaryFn, TernaryFn } from "@tvg/types/Functional";
import type {
  CapiMessages,
  PaymentOptions,
  PaymentOptionsEnum
} from "@tvg/types/ResponsibleGaming";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import FundingMethodsTemplate from "@tvg/atomic-ui/_templates/FundingMethods";
import mediator from "@tvg/mediator";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import {
  rgFundingControlModalOpen,
  rgSubmitModalFinished,
  rgSubmitModalError,
  rgCloseModal,
  rgSetLoading
} from "@tvg/responsible-gaming-modal/src/actions";
import FundingMethodsLib, {
  blockGtmModalOpen,
  blockGtmHandle
} from "@tvg/sh-lib-funding-methods";
import withRouter from "@tvg/utils/withCustomRouter";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { type RouterHistory } from "react-router-dom";

import { paymentOptionsDefault, capiMessagesDefault } from "./configDefault";

type Props = {
  isLogged: boolean,
  accountNumber: string,
  rgFundingControlsPage: {
    fundingControls: CapiMessages
  },
  device: Device,
  paymentMethodNames: PaymentOptions,
  fundingControlModalOpen: TernaryFn<
    string,
    UnaryFn<PaymentOptionsEnum, void>,
    NullaryFn<void>,
    void
  >,
  setModalSuccess: UnaryFn<NullaryFn<void>, void>,
  setModalClose: NullaryFn<void>,
  setModalError: NullaryFn<void>,
  depositURL: string,
  setModalLoading: UnaryFn<boolean, void>,
  isPawsWalletAvailable: boolean,
  history: RouterHistory
};

const parsePaymentOptions = (paymentOptions: PaymentOptions) => {
  const { ACH, CASH, CC, CHK, DEB, IBC, MPAK, PAY, APPL, PNM, PPAL, SLP, MZM } =
    paymentOptionsDefault;
  return {
    ACH: get(paymentOptions, "ACH", ACH),
    CASH: get(paymentOptions, "CASH", CASH),
    CC: get(paymentOptions, "CC", CC),
    CHK: get(paymentOptions, "CHK", CHK),
    DEB: get(paymentOptions, "DEB", DEB),
    IBC: get(paymentOptions, "IBC", IBC),
    MPAK: get(paymentOptions, "MPAK", MPAK),
    PAY: get(paymentOptions, "PAY", PAY),
    APPL: get(paymentOptions, "APPL", APPL),
    PNM: get(paymentOptions, "PNM", PNM),
    PPAL: get(paymentOptions, "PPAL", PPAL),
    SLP: get(paymentOptions, "SLP", SLP),
    MZM: get(paymentOptions, "MZM", MZM)
  };
};

export const blockMethod = (
  accountNumber: string,
  paymentType: PaymentOptionsEnum,
  setIsLoading: UnaryFn<boolean, void>,
  setShouldUpdate: UnaryFn<boolean, void>,
  setModalSuccess: UnaryFn<NullaryFn<void>, void>,
  setModalError: NullaryFn<void>,
  setModalClose: NullaryFn<void>
) => {
  setIsLoading(true);
  return FundingMethodsLib.blockPaymentMethod(accountNumber, paymentType)
    .then(() => {
      setShouldUpdate(true);
      setModalSuccess(() => {
        blockGtmHandle("back");
        setModalClose();
      });
    })
    .catch(() => {
      setModalError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const onErrorButtonCallback = () => window.location.reload();

export const cancelCallback = (props: Props) => () => {
  blockGtmHandle("cancel");
  props.setModalClose();
};

export const submitCallback =
  (
    paymentOption: PaymentOptionsEnum,
    props: Props,
    setShouldUpdate: UnaryFn<boolean, void>
  ) =>
  () => {
    blockGtmHandle("confirm block");
    blockMethod(
      props.accountNumber,
      paymentOption,
      props.setModalLoading,
      setShouldUpdate,
      props.setModalSuccess,
      props.setModalError,
      props.setModalClose
    );
  };

export const handleBlockButtonClick =
  (props: Props, setShouldUpdate: UnaryFn<boolean, void>) =>
  (paymentOptionName: string, paymentOption: PaymentOptionsEnum) => {
    blockGtmModalOpen(paymentOptionName);
    props.fundingControlModalOpen(
      paymentOptionName,
      submitCallback(paymentOption, props, setShouldUpdate),
      cancelCallback(props)
    );
  };

export const handleAddMethodClick =
  (
    device: Device,
    depositURL: string,
    isLogged: boolean,
    history: RouterHistory
  ) =>
  (event: Event) => {
    if (!isLogged) {
      event.preventDefault();
      event.stopPropagation();

      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              if (device === "desktop") {
                if (isTvg5()) {
                  history.push(depositURL);
                } else {
                  setTimeout(() => {
                    mediator.base.dispatch({
                      type: "TVG4_NAVIGATION",
                      payload: { route: depositURL }
                    });
                  }, 0);
                }
              } else {
                window.location.href = depositURL;
              }
            }
          }
        }
      });
    } else if (device === "desktop" && isLogged) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: depositURL }
      });
    }
  };

export const FundingMethods = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMethods, setActiveMethods] = useState([]);
  const [blockedMethods, setBlockedMethods] = useState([]);
  const isMZMToggleOn = useSelector(getMazoomaToggle);

  useEffect(() => {
    if (shouldUpdate) {
      FundingMethodsLib.fetchPaymentMethods(
        props.accountNumber,
        props.isLogged,
        setActiveMethods,
        setBlockedMethods,
        setHasError,
        isMZMToggleOn
      ).then(() => {
        setShouldUpdate(false);
        setIsLoading(false);
      });
    }
  }, [props.accountNumber, shouldUpdate]);

  return useMemo(
    () => (
      <React.Fragment>
        <FundingMethodsTemplate
          hasError={hasError}
          onErrorButtonCallback={onErrorButtonCallback}
          isLoading={isLoading}
          device={props.device}
          handleBlockButtonClick={handleBlockButtonClick(
            props,
            setShouldUpdate
          )}
          capiMessages={props.rgFundingControlsPage.fundingControls}
          paymentMethodNames={parsePaymentOptions(props.paymentMethodNames)}
          activeMethods={activeMethods}
          blockedMethods={blockedMethods}
          dmaURL={props.depositURL}
          handleAddMethodClick={handleAddMethodClick(
            props.device,
            props.depositURL,
            props.isLogged,
            props.history
          )}
        />
      </React.Fragment>
    ),
    [
      activeMethods,
      blockedMethods,
      props.accountNumber,
      isLoading,
      props.rgFundingControlsPage
    ]
  );
};

FundingMethods.defaultProps = {
  isLogged: false,
  accountNumber: "",
  device: "mobile",
  paymentMethodNames: {
    ACH: "",
    CASH: "",
    CC: "",
    CHK: "",
    DEB: "",
    IBC: "",
    MPAK: "",
    PAY: "",
    APPL: "",
    PNM: "",
    PPAL: "",
    SLP: "",
    MZM: ""
  },
  depositURL: "",
  rgFundingControlsPage: capiMessagesDefault,
  fundingControlModalOpen: noop,
  setModalClose: noop,
  setModalSuccess: noop,
  setModalError: noop,
  setModalLoading: noop,
  isPawsWalletAvailable: false,
  history: noop
};

export const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  fundingControlModalOpen: (
    paymentOptionToBlock: string,
    primaryButtonCallback: NullaryFn<void>,
    secondaryCallback: NullaryFn<void>
  ) =>
    dispatch(
      rgFundingControlModalOpen(
        paymentOptionToBlock,
        primaryButtonCallback,
        secondaryCallback
      )
    ),
  setModalSuccess: (secondaryButtonCallback: NullaryFn<void>) =>
    dispatch(rgSubmitModalFinished(null, secondaryButtonCallback)),
  setModalError: () => dispatch(rgSubmitModalError()),
  setModalClose: () => dispatch(rgCloseModal()),
  setModalLoading: (isLoading: boolean) => {
    dispatch(rgSetLoading(isLoading));
  }
});

const mapStateToProps = (store) => ({
  isLogged: get(store, "userData.logged", false),
  accountNumber: get(store, "userData.user.accountNumber"),
  paymentMethodNames: attempt(() =>
    JSON.parse(get(store, "capi.messages.paymentOptionsDescription"))
  ),
  rgFundingControlsPage: parseJSONCapiMessage(
    store,
    "capi.messages.rgFundingControlsPage",
    capiMessagesDefault
  ),
  isPawsWalletAvailable: get(
    store,
    "capi.featureToggles.pawsWalletAvailable",
    false
  )
});

// $FlowFixMe
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FundingMethods)
);
