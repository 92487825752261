import React, { memo, FC } from "react";
import { useEvents } from "../../../../hooks/useEvents";
import { useColor } from "../../../../theming";
import { Icon } from "../../../icon";
import { StyledButton, StyledText } from "./styled-components";
import { FilterButtonProps } from "./types";
import { useQaLabelActive } from "../../../../hooks";

export const FilterButton: FC<FilterButtonProps> = memo(
  ({
    iconName,
    iconSide = "left",
    label,
    onClick,
    isActive,
    value,
    minWidth,
    qaLabel,
    isStretch,
    ...rest
  }) => {
    const {
      events: { isHovered, isPressed },
      hoverHandlers,
      pressHandlers
    } = useEvents({});
    const qaLabelProps = useQaLabelActive(
      `${qaLabel}-${value}`,
      isActive ? "select" : ""
    );

    const handleClick = () => {
      onClick?.(value);
    };

    return (
      <StyledButton
        {...hoverHandlers}
        {...pressHandlers}
        {...rest}
        {...qaLabelProps}
        isActive={isActive}
        isPressed={isPressed}
        isHovered={isHovered}
        iconSide={iconSide}
        isStretch={isStretch}
        onPress={handleClick}
        minWidth={minWidth}
      >
        {iconName && (
          <Icon
            name={iconName}
            lineColor={
              isActive ? useColor("blue_accent.700") : useColor("grey.900")
            }
            size="s"
          />
        )}
        {label && (
          <StyledText isActive={isActive} size={rest.size}>
            {label}
          </StyledText>
        )}
      </StyledButton>
    );
  }
);

export type { FilterButtonProps };
