import { RaceWagerType } from "@tvg/ts-types/Race";
import { BetTypeCodesEnum } from "../../types";

export interface BetButtonsProps {
  /**
   * Callback for button click
   */
  onPress: (type: string, isActive: boolean, position?: number) => void;
  /**
   * Change the state of the buttons to selected
   * @default false
   */
  activeButtons: boolean[];
  /**
   * Tells the betButtons witch buttons to render
   * @default WN
   */
  wagerType?: RaceWagerType;
}

export { BetTypeCodesEnum };
