// @flow
import type { Location, Brand } from "./types.js.flow";

export default (brand: Brand): Location => {
  switch (brand) {
    case "4njbets":
      return "nj";
    case "pabets":
      return "pa";
    case "iowa":
      return "ia";
    default:
      return "all";
  }
};
