// @flow
const { STORYBLOK_TOKENS } = require("../../utils/fdr/storyblok.js");
const { APPTENTIVE_TOKENS } = require("../../utils/fdr/apptentive.js");

module.exports = {
  domain: {
    desktop: "racing.fanduel.com",
    mobile: "racing.fanduel.com",
    talentPicks: "racing.fanduel.com/talent-picks/",
    accountWallet: "account.racing.fanduel.com"
  },
  service_host: "service.racing.fanduel.com",
  externalDomain: {
    FDTermsConditions: "https://fanduel.com/terms",
    FDPolicy: "https://fanduel.com/privacy",
    FDRLoginBridge: "https://fanduel.com/sso-auth/fdracing",
    FDRSignup:
      "https://www.fanduel.com/join?next=%2Fsso-auth%2Ffdracing%3Fredirect_uri%3D"
  },
  externalServices: {
    fanduel: "https://api.fanduel.com"
  },
  clientId: "a301e728606047a5b6522727d495b3ed",
  brazeId: "e0b90c9d-dfd9-4809-a627-565a5cd7f3b2",
  storyblokToken: STORYBLOK_TOKENS.PRODUCTION,
  storyblokVersion: "published",
  siftAccountId: "60f88a09293fb116c8220750",
  siftApiKey: "2cd7fd28042a1454",
  siftBeaconKey: "df80113946",
  appTentiveIos: APPTENTIVE_TOKENS.IOS.PRODUCTION,
  appTentiveAndroid: APPTENTIVE_TOKENS.ANDROID.PRODUCTION,
  appTentiveWeb: APPTENTIVE_TOKENS.WEB.PRODUCTION
};
