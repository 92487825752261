import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Bets: FC = () => (
  <>
    <Path
      d="M46.9688 6.78125H14.0312C11.8911 6.78125 10.1562 8.51615 10.1562
      10.6562V56.1875L18.875 52.3125L24.6875 56.1875L30.5 52.3125L36.3125
      56.1875L42.125 52.3125L50.8438 56.1875V10.6562C50.8438 8.51615 49.1089
      6.78125 46.9688 6.78125Z"
      fill="#CDDFFA"
    />
    <Path
      d="M18.875 22.2812H30.5M18.875 33.9062H42.125M14.0312
      6.78125H46.9688C49.1089 6.78125 50.8438 8.51615 50.8438
      10.6562V56.1875L42.125 52.3125L36.3125 56.1875L30.5 52.3125L24.6875
      56.1875L18.875 52.3125L10.1562 56.1875V10.6562C10.1562 8.51615 11.8911
      6.78125 14.0312 6.78125Z"
      stroke="#3574D4"
      strokeWidth="3"
    />
  </>
);
