import { useEffect, useState, useCallback } from "react";

const useSubscription = (graph, variables, query, isTabClosed = false) => {
  const [subscriptionData, setData] = useState(null);
  const [subscriptionHelper, setSubscription] = useState(null);

  const onCleanSubscription = useCallback(() => {
    if (subscriptionHelper) {
      graph.stop();
      setData(null);
      if (typeof subscriptionHelper.unsubscribe === "function") {
        subscriptionHelper.unsubscribe();
      }
    }
  }, [subscriptionHelper]);

  useEffect(() => {
    if (variables && isTabClosed) {
      const observer = graph.subscribe({
        query,
        fetchPolicy: "cache-and-network",
        ssr: false,
        variables
      });

      const subscription = observer.subscribe({
        next(result) {
          setData(result.data);
        }
      });

      setSubscription(subscription);
    }
  }, [JSON.stringify(variables), isTabClosed]);

  return {
    data: subscriptionData,
    subscriptionHelper,
    onCleanSubscription
  };
};

export default useSubscription;
