import styled, { css } from "styled-components/native";
import { rgba } from "polished";

import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose
} from "../../../../theming/styledSystem";

const styleFns = compose(color, background, borderRadius, width);

export const Container = styled.View`
  display: flex;
`;

export const ContentContainer = styled.View<{ layer: number }>`
  position: absolute;
  z-index: ${({ layer }) => layer};
  display: flex;
  flex-direction: column;
  ${space}
`;

export const Dropdown = styled.View<{ isDarkMode: boolean }>`
  border-width: 1px;
  ${({ theme, isDarkMode }) =>
    css`
      background-color: ${isDarkMode
        ? theme.colors.blue["900"]
        : theme.colors.white["900"]};
      border-color: ${theme.colors.blue["100"]};
      border-radius: ${theme.radii.s};
      filter: drop-shadow(0 1px 3px ${rgba(theme.colors.blue[900], 0.14)});
    `}
  ${styleFns}
`;
