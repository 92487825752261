import React, { useState, memo } from "react";
import {
  ButtonContainer,
  ButtonText,
  StyledButton,
  TextContainer,
  ButtonRing
} from "./styled-components";
import { Icon } from "../../../icon";
import { ButtonProps } from "./types";

export const Button = memo(
  ({
    onPress,
    icon,
    qaLabel,
    text,
    isSelected = false,
    isFullWidth = false,
    isFirstChild = false,
    isLastChild = false,
    hasShadow = false,
    isHoveringAll = false,
    onMouseEnter,
    onMouseLeave,
    ...props
  }: ButtonProps) => {
    const [isHovering, setIsHovering] = useState(false);

    const manageIconColor =
      isSelected || isHovering || isHoveringAll
        ? "blue_accent.700"
        : "grey.900";

    return (
      <ButtonContainer
        onMouseEnter={() => {
          setIsHovering(true);
          if (typeof onMouseEnter === "function") {
            onMouseEnter();
          }
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          if (typeof onMouseLeave === "function") {
            onMouseLeave();
          }
        }}
        isFullWidth={isFullWidth}
        isActive={isSelected}
        isHovering={isHovering || isHoveringAll}
        {...props}
      >
        <StyledButton
          onPress={onPress}
          qaLabel={qaLabel}
          isActive={isSelected}
          isFullWidth={isFullWidth}
          isHovering={isHovering || isHoveringAll}
          hasShadow={hasShadow}
        >
          <TextContainer>
            {icon && (
              <Icon
                name={icon}
                size="s"
                lineColor={
                  isSelected && isHovering ? "blue_accent.800" : manageIconColor
                }
                mr="space-3"
              />
            )}
            <ButtonText
              isHovering={isHovering || isHoveringAll}
              isActive={isSelected}
              qaLabel="button-text"
            >
              {text}
            </ButtonText>
          </TextContainer>
        </StyledButton>
        <ButtonRing
          pointerEvents="none"
          isFirstChild={isFirstChild}
          isLastChild={isLastChild}
          isActive={isSelected}
          isHovering={isHovering || isHoveringAll}
        />
      </ButtonContainer>
    );
  }
);

export default Button;
