// @flow
import type { Env, Content } from "./types.js.flow";

import configBase from "./base";
import { BRAND_ALL } from "./brand";
import defaults from "../content/default";
import base from "../content/base";
import tvgBrand from "../content/brand";

export default (env: Env): Content => {
  const confDefault: Content = {
    tvg: defaults,
    pabets: defaults,
    iowa: defaults,
    "4njbets": defaults,
    fdr: defaults
  };

  const content: {
    production: Content,
    staging: Content,
    qa: Content
  } = {
    production: { ...confDefault },
    staging: { ...confDefault },
    qa: { ...confDefault }
  };

  Object.keys(content).forEach((envName) =>
    BRAND_ALL.map((brand) => {
      const baseConf = base[envName];
      const brandConf = tvgBrand[envName][brand];
      content[envName][brand] = {
        ...content[envName][brand],
        ...baseConf,
        ...brandConf
      };
      return brand;
    })
  );

  const config = BRAND_ALL.reduce(
    (acc, brand) => ({
      ...acc,
      [brand]: configBase.config(content[env][brand])
    }),
    {}
  );

  // $FlowFixMe
  return config;
};
