import React, { useContext, useEffect, useRef } from "react";
import { Animated, StyleSheet } from "react-native";
import { colors } from "../../../../theming/themes/common";
import { TabsContext } from "../../tabs";
import { TabSelectionIndicatorProps } from "./types";

export const TabSelectionIndicator = ({
  width,
  x
}: TabSelectionIndicatorProps) => {
  const { selectedValue, tabsLayout } = useContext(TabsContext);
  const translation = useRef(new Animated.Value(x)).current;

  useEffect(() => {
    Animated.timing(translation, {
      toValue: x,
      useNativeDriver: true,
      duration: 200
    }).start();
  }, [selectedValue, tabsLayout]);

  return (
    <Animated.View
      style={[
        styles.slider,
        {
          width,
          backgroundColor: colors.blue_accent[500],
          transform: [{ translateX: translation }]
        }
      ]}
    />
  );
};

const styles = StyleSheet.create({
  slider: {
    position: "absolute",
    top: 46,
    height: 2
  }
});

export { TabSelectionIndicatorProps };
