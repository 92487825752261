import { RefAttributes } from "react";
import styled, { css, DefaultTheme } from "styled-components/native";
import { initialWindowMetrics } from "react-native-safe-area-context";
import { ThemedStyledProps } from "styled-components";
import { View, ViewProps, Modal, TouchableWithoutFeedback } from "react-native";
import {
  maxWidth,
  MaxWidthProps,
  padding,
  PaddingProps
} from "../../theming/styledSystem";
import { IsMobileProp, IsNativeProp, ModalTypeProp } from "./types";

export const INSET_VERTICAL = initialWindowMetrics
  ? initialWindowMetrics?.insets?.bottom + initialWindowMetrics?.insets?.top
  : 0;

export const INSET_BOTTOM = initialWindowMetrics?.insets?.bottom || 0;

const PADDING_PROPS = [
  "p",
  "padding",
  "pt",
  "paddingTop",
  "pb",
  "paddingBottom",
  "pl",
  "paddingLeft",
  "pr",
  "paddingRight",
  "py",
  "paddingY",
  "px",
  "paddingX"
];

export const StyledModal = styled(Modal)`
  background: ${({ theme }) => theme.colors.white["900"]};
`;

export const TouchableOverlayContainer = styled(TouchableWithoutFeedback)``;

export const TouchableContainer = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const OverlayContainer = styled(View)<ModalTypeProp>`
  background-color: ${({ theme, type }) =>
    type === "full-width-page"
      ? theme.colors.white["900"]
      : theme.colors.black["700"]};
  bottom: 0;
  top: 0;
  width: 100%;
  position: absolute;
`;

export const ContentContainerFullscreen = styled(View)`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: absolute;
  top: ${(props) => props.theme.space["space-5"]};
  bottom: 0;
  background: ${({ theme }) => theme.colors.white["900"]};
  width: 100%;
  height: 100%;
  padding-bottom: ${INSET_VERTICAL}px;
`;

export const ContentContainerFullWidthPage = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white["900"]};
  width: 100%;
  padding-bottom: ${INSET_VERTICAL}px;
`;

const LIGHTBOX_DESKTOP_AND_TABLET_WIDTH = 391;
export const LIGHTBOX_MARGIN_SIDE = 12;
export const LIGHTBOX_MARGIN = LIGHTBOX_MARGIN_SIDE * 2;
export const ContentContainerLightbox = styled(View)<
  {
    height: number;
    width: number;
    contentHeight: number;
  } & IsNativeProp
>`
  position: absolute;
  width: ${(props) => props.width - LIGHTBOX_MARGIN}px;
  height: ${(props) => props.height - LIGHTBOX_MARGIN - INSET_VERTICAL}px;
  display: flex;
  margin: ${(props) => props.theme.space["space-4"]};
  ${(props) =>
    props.contentHeight < props.height
      ? css`
          justify-content: center;
        `
      : `justify-content: flex-start`};
  align-items: center;
`;

export const ContentInnerLightbox = styled(View)<
  {
    height: number;
    contentHeight?: number;
  } & IsNativeProp &
    IsMobileProp &
    MaxWidthProps
>`
  border-top-right-radius: ${(props) => props.theme.radii.s};
  border-top-left-radius: ${(props) => props.theme.radii.s};
  border-bottom-right-radius: ${(props) => props.theme.radii.s};
  border-bottom-left-radius: ${(props) => props.theme.radii.s};
  background: ${({ theme }) => theme.colors.white["900"]};
  width: 100%;
  max-width: ${(props) =>
    props.isMobile ? "100%" : `${LIGHTBOX_DESKTOP_AND_TABLET_WIDTH}px`};
  overflow: hidden;
  ${(props) => {
    if (!props.isNative) return `height: auto;`;
    return props?.contentHeight &&
      props.contentHeight < props.height - LIGHTBOX_MARGIN
      ? css`
          height: ${props.contentHeight}px;
        `
      : css`
          height: ${props.height - INSET_VERTICAL}px;
        `;
  }};
  max-height: ${(props) => props.height - LIGHTBOX_MARGIN}px;
  ${maxWidth}
`;

export const ContentContainerFlexible = styled(View)<{
  contentHeight: number;
  height: number;
}>`
  position: absolute;
  margin-top: ${LIGHTBOX_MARGIN_SIDE}px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) =>
    props.contentHeight < props.height
      ? props.contentHeight + INSET_VERTICAL
      : props.height - LIGHTBOX_MARGIN_SIDE}px;
`;

export const ContentInnerFlexible = styled(View)`
  position: relative;
  border-top-right-radius: ${(props) => props.theme.radii.s};
  border-top-left-radius: ${(props) => props.theme.radii.s};
  background: ${({ theme }) => theme.colors.white["900"]};
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-bottom: ${INSET_VERTICAL}px;
`;

export const childrenSpacing = (
  props: ThemedStyledProps<
    ViewProps & RefAttributes<View> & IsMobileProp & ModalTypeProp,
    DefaultTheme
  >
) =>
  `padding: ${
    props.type === "lightbox" && !props.isMobile
      ? props.theme.space["space-6"]
      : props.theme.space["space-4"]
  }`;

export const ChildrenContainer = styled(View)<
  IsMobileProp &
    ModalTypeProp & { hasPages: boolean; pageHeight: number } & PaddingProps
>`
  ${(props) => (!props.hasPages ? childrenSpacing(props) : ``)};
  ${(props) =>
    !props.hasPages &&
    // check if has any padding prop
    Object.keys(props).some((prop) => PADDING_PROPS.includes(prop))
      ? padding(props)
      : ``};
  ${(props) =>
    props.type === "full-width-page" || props.type === "fullscreen"
      ? css`
          height: ${props.pageHeight}px;
        `
      : ``};
`;

export const StickyAreaContainer = styled(View)<IsMobileProp & ModalTypeProp>`
  ${childrenSpacing};
  box-shadow: 0px -2px 4px rgba(17, 43, 68, 0.14);
  background-color: ${(props) => props.theme.colors.white[900]};
`;
