import { AlertInlineVariants } from "@tvg/design-system/src/components/alertInline/types";

export type NullaryFn<R> = () => R;
export type UnaryFn<A, R> = (a: A) => R;
export type BinaryFn<A, B, R> = (a: A, b: B) => R;
export type TernaryFn<A, B, C, R> = (a: A, b: B, c: C) => R;
export type QuadFn<A, B, C, D, R> = (a: A, b: B, c: C, d: D) => R;
export type QuinFn<A, B, C, D, F, R> = (a: A, b: B, c: C, d: D, f: F) => R;

export type ConfirmationModalType = "BETTING" | "NOTIFICATIONS" | "";

export type PreferencesTabEnum = "ACCOUNT" | "NOTIFICATIONS" | "BETTING" | "";

export type GraphWagerType = {
  type: {
    id: number;
    name: string;
  };
};

export type GraphResponse = {
  wagerTypes: GraphWagerType[];
};

enum WagerGroupsEnum {
  WIN = "10",
  PLACE = "20",
  SHOW = "30",
  EXACTA = "110",
  TRIFECTA = "160",
  SUPERFECTA = "210",
  SUPERHIGHFIVE = "260",
  PICK = "P"
}

type WagerGroupTypes =
  | WagerGroupsEnum.WIN
  | WagerGroupsEnum.PLACE
  | WagerGroupsEnum.SHOW
  | WagerGroupsEnum.EXACTA
  | WagerGroupsEnum.TRIFECTA
  | WagerGroupsEnum.SUPERFECTA
  | WagerGroupsEnum.SUPERHIGHFIVE
  | WagerGroupsEnum.PICK;

export type WagerGroups = {
  [key in WagerGroupTypes]: WagerGroupsItem;
};

export type PreferencesBetAmount = {
  "10": string;
  "20": string;
  "30": string;
  "110": string;
  "160": string;
  "210": string;
  "260": string;
  P: string;
};

export type WagerGroupsItem = {
  errorMessage?: string;
  group: string;
  title: string;
  value: string;
};

export type DefaultBetPrefs = {
  defaultBetType: string;
  useMinimumBetAmount: boolean;
  useCustomBetAmount: boolean;
  default_bet_amount: PreferencesBetAmount;
};

export type AlertTypePref =
  | AlertInlineVariants
  | "error-single"
  | "success.mute"
  | "success.unmute"
  | "error.mute"
  | "error.unmute";

export type AlertPrefs = {
  show: boolean;
  type: AlertTypePref;
  isToggling: boolean;
  shouldShowButton?: boolean;
};

export type BettingCapi = {
  betConfirmation: {
    title: string;
    description: string;
    label: string;
  };
  betType: {
    title: string;
  };
  betAmount: {
    title: string;
    label: Array<string>;
    message: string;
    placeholder: string;
  };
  resetButton: string;
};

export type NotificationsCapi = {
  raceAlerts: {
    title: string;
    description: string;
    label: [string, string, string];
    footer: Array<string>;
  };
  offersNews: {
    title: string;
    description: string;
    label: Array<string>;
  };
  promotionActivity: {
    title: string;
    description: string;
    label: Array<string>;
  };
  talentPicks: {
    title: string;
    description: string;
    label: Array<string>;
    btnText: string;
  };
  other: {
    title: string;
    description: string;
    label: Array<string>;
  };
  muteBtn: {
    mute: string;
    unmute: string;
  };
  alerts: {
    error: {
      title: string;
      message: string;
    };
  };
};

export type AlertsCapi = {
  error: {
    title: string;
    message: string;
    hasButton: boolean;
  };
  "error-single": {
    title: string;
    message: string;
    hasButton: boolean;
  };
  success: {
    title: string;
    message: string;
    hasButton: boolean;
  };
};

export interface ConfirmationModal {
  isOpen: boolean;
  type: ConfirmationModalType;
}

export interface ConfirmModalMessage {
  BETTING: {
    title: string;
    firstParagraph: string;
    btnText: string;
  };
}

export enum ToggleStateEnum {
  OFF = "0",
  ON = "1"
}

export interface AccountPrefsMessage {
  account: {
    auth: {
      title: string;
      toggleTitle: string;
    };
  };
}

export type RaceMtpAlertBlock = {
  name: string;
  onClick: () => void;
  enable: boolean;
};

export type MtpOptions = "0" | "2" | "5";

export enum RaceMtpOptions {
  zero = "0",
  two = "2",
  five = "5"
}

export type TalentAlertsCapi = {
  headerMessage: string;
};

export type TalentType = {
  isActive: boolean;
  name: string;
  phrases: Array<string>;
  pictureUrl: string;
  popularity: number;
  talentId: string;
  tvgAccountId: string;
};

export type SubscribedType = {
  favoriteId: number;
  entityDob: number;
  entityName: string;
  entityType: string;
  subscriptions: Array<SubscriptionsType>;
};

export type SubscriptionsType = {
  channels: Array<SubscriptionsChannelsType>;
  eventType: string;
};

export type SubscriptionsChannelsType = {
  type: string;
};

export enum NotificationSwitch {
  push_offers_and_news = "offersPush",
  email_offers_news = "offersEmail",
  push_promotion_activity = "promoPush",
  email_promotion_activity = "promoEmail",
  talent_picks = "talentPicksPush",
  push_fanduel_comms = "otherFanduelPush",
  email_fanduel_comms = "otherFanduelEmail"
}

export type NotificationsSwitchState = {
  [NotificationSwitch.push_offers_and_news]: boolean;
  [NotificationSwitch.email_offers_news]: boolean;
  [NotificationSwitch.push_promotion_activity]: boolean;
  [NotificationSwitch.email_promotion_activity]: boolean;
  [NotificationSwitch.talent_picks]: boolean;
  [NotificationSwitch.push_fanduel_comms]: boolean;
  [NotificationSwitch.email_fanduel_comms]: boolean;
};

export enum PushStringTypes {
  push_offers_and_news = "push_offers_and_news",
  push_promotion_activity = "push_promotion_activity",
  talent_picks = "talent_picks",
  push_fanduel_comms = "push_fanduel_comms"
}

export type PushTypes = {
  [PushStringTypes.push_offers_and_news]?: boolean;
  [PushStringTypes.push_promotion_activity]?: boolean;
  [PushStringTypes.talent_picks]?: boolean;
  [PushStringTypes.push_fanduel_comms]?: boolean;
};

export enum SubscriptionGroupEnum {
  email_offers_news = "email_offers_news",
  email_promotion_activity = "email_promotion_activity",
  email_fanduel_comms = "email_fanduel_comms"
}

export type SubscriptionGroupType = {
  name: SubscriptionGroupEnum;
  isSubscribed: boolean;
};

export type EmailParams = {
  accountId: string;
  email: string;
  category: SubscriptionGroupEnum;
};

type UnsubscribeBodyContent = {
  description: string;
};

export interface MessageBlock {
  name: string;
  title: string;
  bodyContent: UnsubscribeBodyContent[];
  label?: string;
  buttonTextPrimary?: string;
  buttonTextSecondary: string;
  secondaryUrl?: string;
}

export interface EmailUnsubscribeMessage {
  unsubscribe: MessageBlock;
  confirmation: MessageBlock;
  error: MessageBlock;
  fallback: MessageBlock;
  subscriptionGroups: { [key: string]: string };
}

export interface EmailUnsubscribeFdrMessage {
  unsubscribe: MessageBlock;
  confirmation: MessageBlock;
  error: MessageBlock;
  fallback: MessageBlock;
}

export interface EmailSubscribeFdrMessage {
  subscribe: MessageBlock;
  confirmation: MessageBlock;
  error: MessageBlock;
  fallback: MessageBlock;
}

export type Address = {
  streetNumber: string;
  address1: string;
  address2: string;
  city: string;
  stateAbbr: string;
  zip: string;
};

export type User = {
  accountNumber: string;
  firstName: string;
  lastName: string;
  primaryPhone: string;
  wagerStatus: number;
  emailAddress: string;
  homeAddress: Address;
  signalProviderId: string;
  userName: string;
  profile: string;
  pin: string;
};

export type LoginServerResponse = {
  userDetails: User;
};

export type LoginSuccess = {
  status: string;
  data?: LoginServerResponse;
  fromLogin?: boolean;
};

export type LoginError = {
  status?: string;
  message?: string;
  redirectUrl?: string;
};
