import { RefObject } from "react";
import { View } from "react-native";

import { Layout } from "../types";

const measureLayout = (ref: RefObject<View>) =>
  new Promise<Layout>((resolve) => {
    if (ref.current) {
      ref.current.measureInWindow(
        (x: number, y: number, width: number, height: number) => {
          resolve({ x, y, width, height });
        }
      );
    }
  });

export default measureLayout;
