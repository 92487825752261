import React from "react";
import { useQaLabel } from "../../../hooks";
import { Icon } from "../../icon";
import { TagIconProps } from "./types";
import { TagCircleContainer } from "./styled-components";
import { useVariantColors } from "../hooks/useVariantColors";

export const TagIcon = ({
  qaLabel = "tag-icon",
  label,
  icon,
  ...props
}: TagIconProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { color, bgColor } = useVariantColors(props.variant);

  return (
    <>
      <TagCircleContainer
        accessibilityLabel={label}
        {...props}
        {...viewTestProps}
      >
        <Icon
          size="s"
          name={icon}
          backgroundColor={bgColor}
          lineColor={color}
        />
      </TagCircleContainer>
    </>
  );
};

export { TagIconProps };
export default TagIcon;
