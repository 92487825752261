"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

let cachedMessages = {};

const parseMessages = messagesData => {
  let messages = {};
  const data = (0, _utils.attempt)(() => JSON.parse(decodeURIComponent(messagesData)));

  if (!(data instanceof Error) && typeof data === "object" && data.response) {
    messages = data.response;
  }

  return messages;
};

const readMessages = (namespaces = undefined) => {
  let messagesObject = {};

  if (typeof window !== "undefined" && window.__TVG_GLOBALS__ && window.__TVG_GLOBALS__.MESSAGES) {
    messagesObject = parseMessages(window.__TVG_GLOBALS__.MESSAGES);
    cachedMessages = { ...messagesObject,
      ...cachedMessages
    };
    messagesObject = cachedMessages;
  }

  if (cachedMessages && Object.keys(cachedMessages).length > 0) {
    if (namespaces) {
      messagesObject = Object.assign({}, ...namespaces.map(name => cachedMessages[name]));
    } else {
      const namespaceMap = Object.keys(cachedMessages);
      messagesObject = Object.assign({}, ...namespaceMap.map(name => cachedMessages[name]));
    }
  }

  return messagesObject;
};

const getMessages = (namespaces, capiEndpoint, product, device, brand, forceFetch) => {
  if (!namespaces || !(namespaces instanceof Array)) {
    return Promise.reject(new Error("No namespaces were provided"));
  }

  if (!forceFetch) {
    const messages = readMessages(namespaces);

    if (Object.keys(messages).length > 0 && messages.constructor === Object) {
      return Promise.resolve().then(() => messages);
    }
  }

  const requestOptions = {
    url: `${capiEndpoint}/messages/namespace?product=${product}&device=${device}&brand=${brand}&namespace=${namespaces.join(",")}`,
    method: "GET",
    withCredentials: true
  };
  return (0, _axios.default)(requestOptions).then(result => {
    let messagesMap = [];
    let messages = {};

    if (!result.data || !result.data.response || result.data.response.length < 1) {
      Promise.reject(new Error("No messages returned"));
    } else {
      messagesMap = namespaces.map(k => result.data.response[k]);
      messages = messagesMap.reduce((cache, values, i) => {
        const msgs = {};
        msgs[namespaces[i]] = values;
        return { ...cache,
          ...msgs
        };
      }, messages);
      cachedMessages = { ...cachedMessages,
        ...messages
      };
    }

    return Object.assign({}, ...messagesMap);
  });
};

var _default = {
  readMessages,
  getMessages
};
exports.default = _default;
module.exports = exports.default;