import React from "react";
import { useQaLabel } from "../../hooks/useQaLabel";
import { useVariantColors } from "./hooks/useVariantColors";
import { Icon } from "../icon";
import { StyledText, TagContainer } from "./styled-components";
import { TagIcon } from "./tagIcon";
import { TagProps, TagVariant } from "./types";

export const Tag = ({ qaLabel = "tag", label, icon, ...props }: TagProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { color, bgColor } = useVariantColors(props.variant);

  return (
    <TagContainer {...props} {...viewTestProps}>
      {icon && (
        <Icon
          name={icon}
          size="s"
          backgroundColor={bgColor}
          lineColor={color}
          mr="space-1"
        />
      )}
      <StyledText color={color}>{label}</StyledText>
    </TagContainer>
  );
};

Tag.Icon = TagIcon;

export { TagProps, TagVariant };
