import React from "react";
import { View } from "react-native";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { useQaLabel } from "../../hooks";
import { useTheme } from "../../theming";
import BetSelection from "../betSelections";
import Loading from "../loading";
import { IconButton } from "../iconButton";
import { Tag } from "../tag";
import { Paragraph } from "../typography";
import {
  Container,
  ContentActionsWrapper,
  Content,
  Actions,
  Header,
  VerticalParagraphLeft,
  VerticalParagraphRight
} from "./styled-components";
import { BetTypeCellProps, BetStatusCodesEnum } from "./types";
import { CustomBetSelectionsView } from "./components/CustomBetSelectionsView";

const exoticBets = [
  WagerTypeCodesEnum.EXACTA,
  WagerTypeCodesEnum.TRIFECTA,
  WagerTypeCodesEnum.SUPERFECTA,
  WagerTypeCodesEnum.SUPERHIGHFIVE
];

export const BetTypeCell = ({
  numWagerableRunners,
  type,
  breed = "thoroughbred",
  betTypeName,
  selections,
  completeSelection,
  qaLabel = "bet-type-cell",
  approximatedPayout,
  betAmount,
  betStatus,
  betTicket,
  isLeg,
  scratches,
  onRepeatBetHandler,
  onCancelBetHandler,
  isKey,
  isBox,
  isWheel,
  raceNumber,
  betStatusCode,
  winAmountBet,
  showOnlySelections,
  isRepeatable = true,
  ...rest
}: BetTypeCellProps) => {
  const betTypeCellQaLabel = useQaLabel(qaLabel);
  const { colors } = useTheme();
  const isExoticBet = exoticBets.includes(type);
  const isCustomSelectionsView =
    isKey || isLeg || isBox || isWheel || isExoticBet;

  const isWon = betStatusCode === BetStatusCodesEnum.WINNER;
  const isRefund = betStatusCode === BetStatusCodesEnum.REFUNDED;
  const betStatusCodeMap: Record<
    BetStatusCodesEnum,
    { label: string; value?: number | string }
  > = {
    A: {
      label: approximatedPayout !== "0" ? "APPROX. PAYOUT" : "",
      value: approximatedPayout !== "0" ? approximatedPayout : ""
    },
    R: { label: "REFUND", value: betAmount },
    L: { label: "RETURN", value: "$0.00" },
    W: { label: "WON", value: winAmountBet },
    C: { label: "REFUND", value: betAmount }
  };

  const renderRightColumn = () => {
    switch (betStatus) {
      case "PROCESSING":
        return (
          <View>
            <Loading />
          </View>
        );
      case "ERROR":
        return (
          <View>
            <Tag size="s" variant="warning" label="Failed" />
          </View>
        );
      case "PLACED":
        return (
          <View>
            <Tag size="s" variant="optedin" label="Placed" />
          </View>
        );
      default:
        return (
          <VerticalParagraphRight>
            <Paragraph
              fontFamily={isWon || isRefund ? "medium" : "regular"}
              fontWeight={isWon || isRefund ? 500 : 400}
              color={isWon ? colors.green["700"] : colors.grey["900"]}
              qaLabel="approximate-payout-value"
            >
              <>
                {
                  betStatusCodeMap[betStatusCode || BetStatusCodesEnum.ACTIVE]
                    .value
                }
              </>
            </Paragraph>
            <Paragraph
              qaLabel="approximate-payout"
              fontFamily="condensedRegular"
              color={isWon ? colors.green["700"] : colors.grey["800"]}
              fontSize="xs"
              textAlign={isWon ? "end" : "start"}
            >
              <>
                {
                  betStatusCodeMap[betStatusCode || BetStatusCodesEnum.ACTIVE]
                    .label
                }
              </>
            </Paragraph>
          </VerticalParagraphRight>
        );
    }
  };
  const renderSelections = () =>
    isCustomSelectionsView ? (
      <CustomBetSelectionsView
        isKey={isKey}
        isBox={isBox}
        isLeg={isLeg}
        isWheel={isWheel}
        isExoticBet={isExoticBet}
        completeSelection={completeSelection}
        numWagerableRunners={numWagerableRunners}
        type={type}
        currentRaceNumber={raceNumber}
        scratches={scratches}
        breed={breed}
      />
    ) : (
      <BetSelection
        numWagerableRunners={numWagerableRunners?.[0]}
        selections={selections}
        type={type}
        breed={breed}
        showBetType={false}
        scratches={Array.from(new Set(scratches?.flat(1)))} // remove duplicates
        onHandicaping={rest.onHandicaping}
      />
    );

  return (
    <Container
      showOnlySelections={showOnlySelections}
      {...betTypeCellQaLabel}
      {...rest}
    >
      {!showOnlySelections && (
        <Header>
          <VerticalParagraphLeft>
            <Paragraph qaLabel="bet-amount">{`${betAmount} ${betTypeName}`}</Paragraph>
            <Paragraph
              qaLabel="ticket-amount"
              fontFamily="condensedRegular"
              color={colors.grey["800"]}
              fontSize="xs"
            >
              {`${betTicket} TICKET`}
            </Paragraph>
          </VerticalParagraphLeft>
          {renderRightColumn()}
        </Header>
      )}
      <ContentActionsWrapper>
        <Content hasButtons={!!onRepeatBetHandler || !!onCancelBetHandler}>
          {selections?.length ? renderSelections() : null}
        </Content>
        {onRepeatBetHandler || onCancelBetHandler ? (
          <Actions>
            {onRepeatBetHandler && isRepeatable && (
              <IconButton
                iconName="repeat"
                qaLabel="repeat-bet-btn"
                size="s"
                variant="tertiary"
                tertiaryColor="grey.800"
                onPress={onRepeatBetHandler}
              />
            )}
            {onCancelBetHandler && (
              <IconButton
                iconName="trash"
                qaLabel="cancel-bet-btn"
                size="s"
                variant="tertiary"
                tertiaryColor="grey.800"
                onPress={onCancelBetHandler}
              />
            )}
          </Actions>
        ) : null}
      </ContentActionsWrapper>
    </Container>
  );
};

export default BetTypeCell;
export { BetStatusCodesEnum };
