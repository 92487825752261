"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _queryString = _interopRequireDefault(require("query-string"));

var _lodash = require("lodash");

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const microapps = ["rma", "dma", "prf", "referral"];

var _default = (app = "tvg4", wrapper = false, callback = false, path = "", params = null, relative = false) => (domain, location, product, brazeDevice) => {
  let target = "";
  let query = "";
  const urlParams = typeof params === "string" ? _queryString.default.parse(params) : params;
  const localhost = (0, _utils.isBrowser)() ? `http://${(0, _lodash.get)(window, "location.hostname", "").trim()}${(0, _lodash.get)(window, "location.port", "").trim() ? `:${(0, _lodash.get)(window, "location.port", "").trim()}` : ""}` : `http://localhost:${process ? process.env.PORT : "8080"}`;
  const pathName = `${path.indexOf("/") === 0 ? "" : "/"}${encodeURI(path)}`;

  switch (app) {
    case "rma":
      target = `https://${domain.desktop}/registration${pathName}`;
      break;

    case "dma":
      target = `https://${domain.desktop}/deposit${pathName}`;
      break;

    case "prf":
      target = `https://${domain.desktop}/tvg-password-recovery/#${path ? pathName : "/forgotcredentials"}`;
      break;

    case "rgnative":
      target = `https://${domain.desktop}/responsible-gaming-native`;
      break;

    case "promos":
      target = `https://${domain.desktop}/promos${pathName}`;
      break;

    case "referral":
      target = `https://${domain.desktop}/referral${pathName}`;
      break;

    case "talentPicks":
      target = `https://${domain.talentPicks}${encodeURI(path)}`;
      break;

    case "accountWallet":
      target = `https://${domain.accountWallet || `account.${domain.mobile}`}${pathName}`;
      break;

    case "tvg4":
    case "touch3":
    case "touch2":
    case "ios":
    case "ios2":
      target = (0, _utils.isDev)() ? `${localhost}${pathName}` : `https://${domain.mobile}${pathName}`;
      break;

    default:
      target = (0, _utils.isDev)() ? `${localhost}${pathName}` : `https://${domain.desktop}${pathName}`;
  }

  if (urlParams && Object.keys(urlParams)) {
    query = Object.keys(urlParams).map(key => {
      const value = urlParams[key] || "";
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join("&");
  }

  let url = relative ? pathName : target;

  if (microapps.includes(app)) {
    url = `${target}?location=${location}&product=${product}${wrapper ? "&wrapper=true" : ""}&device=${brazeDevice}`;

    if (callback) {
      url = `${url}&callback=`;

      if (typeof callback === "string") {
        url = `${url}${encodeURIComponent(callback)}`;
      } else if (typeof callback === "boolean" && typeof document === "object") {
        url = `${url}${encodeURIComponent(`${document.location.host}${document.location.pathname !== "/" ? document.location.pathname : ""}${document.location.search}`)}`;
      } else {
        url = `${url}${encodeURIComponent(domain.desktop)}`;
      }
    }

    url = `${url}${query ? "&" : ""}`;
  } else {
    url = `${url}${query ? "?" : ""}`;
  }

  return `${url}${query}`;
};

exports.default = _default;
module.exports = exports.default;