import React, { createContext, useEffect, useState } from "react";
import { View, ScrollView } from "react-native";

import { useQaLabel } from "../../hooks/useQaLabel";
import { TabsProps, TabsContextProps, TabsLayout } from "./types";
import {
  StyledViewContainer,
  ContentView,
  TabHeaderContainer
} from "./styled-components";

const initialState: TabsContextProps = {
  selectedValue: "",
  onChange: () => {},
  isStretched: false,
  tabsLayout: {},
  setTabsLayout: () => {},
  isFullHeight: false
};

export const TabsContext = createContext<TabsContextProps>(initialState);
TabsContext.displayName = "TabsContext";

export const Tabs = ({
  qaLabel,
  children,
  selectedValue,
  onChange,
  isFullHeight = false,
  useViewComponent = false,
  ...rest
}: TabsProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const [tabsLayout, setTabsLayout] = useState<TabsLayout>({});
  const [content, setContent] = useState<React.ReactNode[]>();

  useEffect(() => {
    const childrenElements = React.Children.toArray(children);
    const selectedContent = childrenElements.filter((val) => {
      if (React.isValidElement<{ value: string }>(val)) {
        return val.props.value === selectedValue;
      }
      return val;
    });
    setContent(selectedContent);
  }, [selectedValue, children]);

  let tabList;

  if (children[0]?.props.qaLabel === "tabList") {
    tabList = children[0];
  } else {
    throw new Error(
      "TabList child element is required to be used at first place"
    );
  }

  return (
    <StyledViewContainer
      isFullHeight={isFullHeight}
      {...rest}
      {...viewTestProps}
    >
      <TabsContext.Provider
        value={{
          selectedValue,
          onChange,
          tabsLayout,
          setTabsLayout,
          isFullHeight
        }}
      >
        <TabHeaderContainer>{tabList}</TabHeaderContainer>
        <ContentView
          as={useViewComponent ? View : ScrollView}
          isFullHeight={isFullHeight}
        >
          {content}
        </ContentView>
      </TabsContext.Provider>
    </StyledViewContainer>
  );
};

export { TabsProps };
