import React, { forwardRef } from "react";
import { View, Platform } from "react-native";
import { BRAND_4NGBETS, BRAND_TVG } from "@tvg/conf/cjs/brand";
import { useQaLabel } from "../../hooks/useQaLabel";
import { LogoProps } from "./types";
import { PathIllustration } from "./pathIllustation";
import { PathComplete } from "./pathComplete";
import { useDimentions, DEFAULTS } from "./useDimensions";
import { StyledSVG } from "./styled-components";

enum TYPE_LOGO {
  illustration = "illustration",
  complete = "complete"
}

export const Logo = forwardRef<View, LogoProps>(
  (
    {
      height = DEFAULTS.height,
      isDarkMode = false,
      isOnlyIllustration = false,
      accessibilityLabel = "Home page",
      qaLabel = "logo-label",
      brand = BRAND_TVG,
      ...props
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const typeLogo = isOnlyIllustration
      ? TYPE_LOGO.illustration
      : TYPE_LOGO.complete;

    const getHeightByBrand = () => {
      const heigthBrand = height;
      if (brand === BRAND_4NGBETS) {
        return heigthBrand * 1.5;
      }

      return heigthBrand;
    };

    const { dimension } =
      brand !== "iowa"
        ? useDimentions(getHeightByBrand(), typeLogo)
        : {
            dimension: {
              viewBox: `0 0 103 30`,
              height,
              width: (height * 103) / 30
            }
          };

    return (
      <StyledSVG
        {...viewTestProps}
        {...dimension}
        {...props}
        ref={ref}
        accessible={Platform.OS === "web" ? undefined : true}
        accessibilityRole="image"
        accessibilityLabel={accessibilityLabel}
      >
        {isOnlyIllustration ? (
          <PathIllustration />
        ) : (
          <PathComplete isDarkMode={isDarkMode} brand={brand} />
        )}
      </StyledSVG>
    );
  }
);

export { LogoProps };
