import React, { FC, memo } from "react";
import { RaceInfoProps } from "./types";
import { Paragraph } from "../../../typography";

export const RaceInfo: FC<RaceInfoProps> = memo(({ label, align, qaLabel }) => (
  <Paragraph
    fontFamily="medium"
    textAlign={align}
    qaLabel={`${qaLabel}-race-info`}
    color="grey.900"
  >
    {label}
  </Paragraph>
));
