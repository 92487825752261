/* TODO: move to a global hooks folder */
import { useEffect, RefObject } from "react";
import { View } from "react-native";

export interface CloseOnClickOutside {
  isWeb: boolean;
  refs: RefObject<View>[];
  onPress: () => void;
}

const useCloseOnClickOutside = ({
  isWeb,
  refs,
  onPress
}: CloseOnClickOutside) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      let clickedInRefs = false;
      if (refs) {
        refs.forEach((ref) => {
          if (
            ref &&
            ref.current &&
            ref.current instanceof HTMLElement &&
            ref.current.contains(e.target as HTMLElement)
          ) {
            clickedInRefs = true;
          }
        });
      }

      if (!clickedInRefs) onPress();
    };

    if (isWeb) {
      document.addEventListener("click", listener);
    }

    return () => {
      if (isWeb) {
        document.removeEventListener("click", listener);
      }
    };
  }, []);
};

export default useCloseOnClickOutside;
