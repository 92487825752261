import styled, { css } from "styled-components/native";
import { Platform, View, Text } from "react-native";
import { compose, variant, margin } from "../../theming/styledSystem";
import { Paragraph } from "../typography";

const isWeb = Platform.OS === "web";

export const tagVariants = {
  prop: "variant",
  variants: {
    info: {
      backgroundColor: "blue.000",
      color: "grey.800"
    },
    optedin: {
      backgroundColor: "green.100",
      color: "green.800"
    },
    promo: {
      backgroundColor: "yellow.100",
      color: "yellow.900"
    },
    warning: {
      backgroundColor: "orange.100",
      color: "orange.900"
    },
    highlight: {
      backgroundColor: "blue_accent.100",
      color: "blue_accent.700"
    },
    fav: {
      backgroundColor: "yellow.500",
      color: "black.800"
    }
  }
};

export const tagSizes = {
  prop: "size",
  variants: {
    s: {
      paddingX: "space-2",
      height: "16px"
    },
    m: {
      paddingX: "space-2",
      height: "20px"
    },
    l: {
      paddingX: "space-2",
      height: "24px"
    }
  }
};

export const stylesTagVariants = compose(variant(tagVariants));
const stylesTagSizes = compose(variant(tagSizes));

export const styleFitContent =
  isWeb &&
  css`
    width: fit-content;
  `;

export const TagContainer = styled(View)`
  ${margin}
  ${stylesTagVariants}
  ${stylesTagSizes}
  flex-direction: row;
  border-radius: 99px;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
`;

export const StyledParagraph = styled(Paragraph)`
  text-transform: uppercase;
`;

export const StyledText = styled(Text)<{ color: string }>`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.condensedRegular};
  color: ${({ color }) => color};
`;
