import "styled-components";

export type Devices = "desktop" | "mobile" | "tablet";

export type ColorVariation = {
  [color in
    | "000"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"]: string;
};

export type TintVariation = {
  [tint in "blue_tint" | "green_tint" | "yellow_tint"]: string;
};

export type Colors = {
  blue_accent: ColorVariation;
  green: ColorVariation;
  yellow: ColorVariation;
  blue: ColorVariation;
  orange: ColorVariation;
  red: ColorVariation;
  grey: ColorVariation;
  black: ColorVariation;
  white: ColorVariation;
  tint: TintVariation;
};

export type Fonts = {
  condensedRegular: string;
  regular: string;
  medium: string;
  bold: string;
};

export type FontSizes = {
  [size in "xs" | "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl"]: string;
};

export type Space = {
  [spacing in
    | "space-1"
    | "space-2"
    | "space-3"
    | "space-4"
    | "space-5"
    | "space-6"
    | "space-7"
    | "space-8"]: string;
};

export type LineHeightsBase = {
  [size in "xs" | "s"]: string;
};

export type LineHeights = {
  [size in "xs" | "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl"]: string;
};

export type Radii = {
  [size in "xs" | "s" | "m" | "l"]: string;
};

export interface FontWeights {
  normal: number;
  medium: number;
  bold: number;
}

/** *****************************
 *********** heading ************
 ******************************* */
type HeadingSize = { fontSize: string; lineHeight: string };

export type HeadingSizes = {
  h1: HeadingSize;
  h2: HeadingSize;
  h3: HeadingSize;
};
export interface HeadingFonts {
  fontFamily: string;
  fontWeight: number;
}
export interface HeadingWeights {
  normal: HeadingFonts;
  bold: HeadingFonts;
}

type ButtonVariants =
  | "primary"
  | "secondary"
  | "secondary_dark"
  | "tertiary"
  | "tertiary_dark"
  | "betting"
  | "marketing"
  | "danger";

type IconButtonVariant =
  | "primary"
  | "secondary"
  | "secondary_dark"
  | "tertiary"
  | "tertiary_dark"
  | "betting"
  | "danger";

type ButtonStyleProps = {
  background: string;
  border?: string;
  boxShadow?: string;
  color?: string;
};

export type Buttons = {
  [variant in ButtonVariants]: {
    default: ButtonStyleProps;
    hovered: ButtonStyleProps;
    focused: ButtonStyleProps;
    pressed: ButtonStyleProps;
    loading: ButtonStyleProps;
  };
};

export type IconButtons = {
  [variant in IconButtonVariant]: {
    default: ButtonStyleProps;
    hovered: ButtonStyleProps;
    focused: ButtonStyleProps;
    pressed: ButtonStyleProps;
  };
};

export type Elevations = {
  [elevations in "low" | "medium" | "high"]: string;
};

export type IconSizeVariants = "s" | "m" | "l" | "xl";

export type IconSizeProps = {
  iconSize: string;
  strokeWidth: string;
};

export type IconSizes = {
  [size in IconSizeVariants]: IconSizeProps;
};

export interface Theme {
  name: string;
  colors: Colors;
  space: Space;
  fonts: Fonts;
  fontSizes: FontSizes;
  headingSizes: HeadingSizes;
  lineHeights: LineHeights;
  lineHeightsShorter: LineHeightsBase;
  lineHeightsTaller: LineHeightsBase;
  fontWeights: FontWeights;
  radii: Radii;
  breakpoints: string[];
  buttons: Buttons;
  iconButtons: IconButtons;
  elevations: Elevations;
  iconSizes: IconSizes;
}
