// @flow

module.exports = {
  AMPLITUDE_API_KEYS: {
    QA: "4a003fec5c6b5c9075661fd874943089",
    STAGING: "405e14af37a3efefe3ff469d65339811",
    PRODUCTION: "7a02b3ee70357df0fedb2f073ee6b9d0"
  },
  AMPLITUDE_API_KEYS_XSELL: {
    QA: "64d1bda80ee432dabc671f9f8e7ec563",
    STAGING: "64d1bda80ee432dabc671f9f8e7ec563",
    PRODUCTION: "e23053be3d840ae45b2a1f786ba058f3"
  }
};
