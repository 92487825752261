import styled from "styled-components/native";
import { View, Text } from "react-native";
import { space } from "../../theming/styledSystem";

export const BetTypeSelectorContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  ${space};
  z-index: 1;
  width: 100%;
`;

export const InfoContainer = styled(View)`
  width: 94px;
  flex-grow: 0;
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} ${theme.space["space-4"]}`};
  background-color: ${({ theme }) => theme.colors.grey["000"]};
  border-bottom-color: ${({ theme }) => theme.colors.blue["100"]};
  border-bottom-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.grey["000"]};
  border-top-width: 1px;
  border-right-color: ${({ theme }) => theme.colors.blue["100"]};
  border-right-width: 1px;
  border-top-left-radius: 4px;
`;

export const InfoText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.grey["900"]};
  text-align: center;
  width: 70px;
  height: 40px;
  line-height: 20px;
`;

export const FadeLayer = styled(View)<{
  orientation: "left" | "right";
  width: number;
}>`
  width: ${({ width }) => width}px;
  position: absolute;
  height: 55px;
  left: 94px;
  pointer-events: none;
  background-image: linear-gradient(
    to ${({ orientation }) => orientation},
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 5%
  );
`;

export const BetTypesContainer = styled(View)<{ isOverFlowing: boolean }>`
  flex: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  border-bottom-color: ${({ theme }) => `${theme.colors.blue["100"]}`};
  border-bottom-width: 1px;
  background-color: ${({ theme }) => theme.colors.white["900"]};
  border-top-color: ${({ theme }) => theme.colors.white["900"]};
  border-top-width: 2px;
  width: ${({ isOverFlowing }) =>
    isOverFlowing ? "calc(100% - 166px)" : "calc(100% - 94px)"};
  overflow-x: hidden;
  border-top-right-radius: 4px;
`;

export const Navigation = styled(View)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px 6px 12px;
  background-color: ${({ theme }) => theme.colors.white["900"]};
`;
