"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mobileDetect = _interopRequireDefault(require("mobile-detect"));

var _product = require("./product");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = (userAgent = undefined, product = undefined) => {
  const uaInfo = new _mobileDetect.default(userAgent);
  let device = "desktop";
  device = uaInfo.mobile() && "mobile" || device;
  device = uaInfo.tablet() && "tablet" || device;

  if (device === "desktop") {
    return _product.PRODUCTS_MOBILE.includes(product) ? "tablet" : device;
  }

  return device;
};

exports.default = _default;
module.exports = exports.default;