"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const parseFeatures = featuresData => {
  let features = false;
  const data = (0, _utils.attempt)(() => JSON.parse(decodeURIComponent(featuresData)));

  if (!(data instanceof Error) && typeof data === "object") {
    features = data;
  }

  return features;
};

const readFeatures = () => {
  let featuresObject = false;

  if (typeof window !== "undefined" && window.__TVG_GLOBALS__ && window.__TVG_GLOBALS__.FEATURE_TOGGLES) {
    featuresObject = parseFeatures(window.__TVG_GLOBALS__.FEATURE_TOGGLES);
  }

  return featuresObject;
};

const getFeatures = (service, product, device, brand, serverSide) => {
  const features = readFeatures();

  if (features || typeof window === "undefined" && !serverSide) {
    return Promise.resolve().then(() => features);
  }

  const requestOptions = {
    url: `${service.capi}/featureToggles/${product}/${device}/${brand}`,
    method: "GET",
    withCredentials: true
  };
  return (0, _axios.default)(requestOptions).then(result => result.data ? result.data : Promise.reject(new Error("No feature toggles returned")));
};

var _default = {
  readFeatures,
  getFeatures
};
exports.default = _default;
module.exports = exports.default;