import React, { FC } from "react";
import { Path } from "react-native-svg";

export const Error: FC = () => (
  <>
    <Path
      d="M59.0949 31.0108C59.0949 46.5272 46.5164 59.1058 31 59.1058C15.4835
      59.1058 2.90498 46.5272 2.90498 31.0108C2.90498 15.4944 15.4835 2.91583
      31 2.91583C46.5164 2.91583 59.0949 15.4944 59.0949 31.0108Z"
      fill="#F7D5D5"
    />
    <Path
      d="M19.3751 19.375L31.0001 31M31.0001 31L42.6251 42.625M31.0001 31L19.3751
      42.625M31.0001 31L42.6251 19.375M59.0949 31.0108C59.0949 46.5272 46.5164
      59.1058 31 59.1058C15.4835 59.1058 2.90498 46.5272 2.90498 31.0108C2.90498
      15.4944 15.4835 2.91583 31 2.91583C46.5164 2.91583 59.0949 15.4944
      59.0949 31.0108Z"
      stroke="#C92C2C"
      strokeWidth="3"
    />
  </>
);
