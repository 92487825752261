import styled from "styled-components/native";
import { View, TouchableHighlight } from "react-native";
import { space, compose, variant } from "../../theming/styledSystem";
import { useQaLabel } from "../../hooks/useQaLabel";
import { IconButtonProps } from "./types";

export const iconButtonSizes = {
  s: {
    width: "32px",
    height: "32px",
    padding: "space-2",
    borderRadius: "16px"
  },
  m: {
    width: "44px",
    height: "44px",
    padding: "space-4",
    borderRadius: "22px"
  },
  l: {
    width: "48px",
    height: "48px",
    padding: "14px",
    borderRadius: "24px"
  }
};

const buttonStyles = compose(
  space,
  variant({ prop: "size", variants: iconButtonSizes })
);

export const StyledButton = styled(TouchableHighlight).attrs<{
  qaLabel: string;
}>((props: IconButtonProps) => ({
  accessibilityRole: "button",
  accessibilityLabel: props.textTooltip,
  ...useQaLabel(props.qaLabel)
}))<
  IconButtonProps & {
    hoveredColor: boolean;
    pressedColor: boolean;
    isHovered: boolean;
    isPressed: boolean;
  }
>`
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii.xs};
  ${({ theme, variant: buttonVariant }) =>
    theme.iconButtons[buttonVariant]?.default};
  ${({
    theme,
    variant: buttonVariant,
    isDisabled,
    hoveredColor,
    pressedColor,
    isHovered,
    isPressed,
    isActive
  }) =>
    (!isDisabled &&
      isPressed &&
      (buttonVariant !== "tertiary"
        ? theme.iconButtons[buttonVariant]?.pressed
        : `background-color:${pressedColor}`)) ||
    (!isDisabled &&
      (isHovered || isActive) &&
      (buttonVariant !== "tertiary"
        ? theme.iconButtons[buttonVariant]?.hovered
        : `background-color:${hoveredColor}`))};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  ${buttonStyles}
`;

export const Container = styled(View)`
  align-items: center;
`;
