import React, { createContext } from "react";
import { ButtonBarGroupProps, ButtonBarGroupContextProps } from "./types";
import { StyleViewContainer } from "./styled-components";
import { ScrollViewFading } from "../../scrollViewFading";

const initialState = {
  selectedValue: "",
  onChange: () => {},
  isCondensed: false
};

export const GroupContext =
  createContext<ButtonBarGroupContextProps>(initialState);

export const ButtonBarGroup = ({
  children,
  selectedValue,
  onChange,
  isCondensed = false,
  ...rest
}: ButtonBarGroupProps) => (
  <GroupContext.Provider value={{ selectedValue, onChange, isCondensed }}>
    <StyleViewContainer isCondensed={isCondensed} {...rest}>
      <ScrollViewFading>{children}</ScrollViewFading>
    </StyleViewContainer>
  </GroupContext.Provider>
);
