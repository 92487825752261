import React, { FC, memo } from "react";
import { Paragraph } from "../../../typography";
import { Container } from "../../styled-components";
import { useColor } from "../../../../theming";
import { MtpCellProps } from "../../types";
import { useQaLabel } from "../../../../hooks";

export const Default: FC<
  Pick<MtpCellProps, "withBackground" | "align" | "qaLabel">
> = memo(({ withBackground, align, qaLabel = "mtp-cell", ...rest }) => {
  const qaLabelProps = useQaLabel(qaLabel);

  return (
    <Container
      withBackground={withBackground}
      align={align}
      bgColor={useColor("grey.000")}
      lineColor={useColor("blue.100")}
      {...qaLabelProps}
      {...rest}
    >
      <Paragraph
        fontFamily="bold"
        textAlign={align}
        qaLabel={`${qaLabel}-available-bet-types`}
        fontSize="16px"
        lineHeight="20px"
        color="grey.900"
      >
        Available bet types
      </Paragraph>
    </Container>
  );
});
