import React from "react";
import { BaseHeader } from "../baseHeaders";
import { PagesHeaderProps } from "../baseHeaders/types";

export const PageHeader = ({
  qaLabel = "pageHeader",
  title,
  subtitle = "",
  subtitleType = "description",
  hasShadow = false,
  linkUrl,
  onBack
}: PagesHeaderProps) => (
  <BaseHeader
    qaLabel={qaLabel}
    title={title}
    subtitle={subtitle}
    subtitleType={subtitleType}
    hasShadow={hasShadow}
    linkUrl={linkUrl}
    onBack={onBack}
  />
);

export { PagesHeaderProps };
export default PageHeader;
