export enum DEFAULTS {
  height = 18,
  widthComplete = 103,
  widthIllustration = 33
}

interface dimensionProps {
  viewBox: string;
  height: number;
  width: number;
}

const defaultDimentions = {
  complete: [DEFAULTS.widthComplete, DEFAULTS.height],
  illustration: [DEFAULTS.widthIllustration, DEFAULTS.height]
};

export const useDimentions = (
  height: number,
  typeLogo: "complete" | "illustration"
) => {
  const [w, h] = defaultDimentions[typeLogo];
  const dimension: dimensionProps = {
    viewBox: `0 0 ${w} ${h}`,
    height,
    width: (w / h) * height
  };
  return { dimension };
};
