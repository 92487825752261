import React from "react";
import { Metrics } from "react-native-safe-area-context";
import { TvgConfProps, TvgConfProvider } from "./tvgConfProvider";
import {
  TVGThemeProvider,
  TVGThemeProviderProps
} from "../theming/components/themeProvider";
import { TvgSafeAreaProvider } from "./tvgSafeAreaProvider";

interface DesignSystemProviderProps {
  /** Children element to be rendered. Normally the DesignSystemProvider should be added at the top level of an application. */
  children: JSX.Element;
  /** Override internal providers props */
  overrides?: {
    /** Provide a custom device, brand or product */
    tvgConf?: Partial<TvgConfProps>;
    /** Provide a custom theme or device  */
    tvgTheme?: Partial<TVGThemeProviderProps>;
    /** Provide a custom safe area initial metrics  */
    tvgSafeAreaMetrics?: Metrics;
  };
}

export const DesignSystemProvider = (props: DesignSystemProviderProps) => {
  const { children, overrides } = props;

  return (
    <TvgConfProvider {...overrides?.tvgConf}>
      <TVGThemeProvider {...overrides?.tvgTheme}>
        <TvgSafeAreaProvider {...overrides?.tvgSafeAreaMetrics}>
          {children}
        </TvgSafeAreaProvider>
      </TVGThemeProvider>
    </TvgConfProvider>
  );
};

export default DesignSystemProvider;
