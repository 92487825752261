import React, { forwardRef, useState, useCallback } from "react";
import { TouchableHighlight } from "react-native";

import { SortProps } from "./types";
import { useEvents } from "../../hooks/useEvents";
import { TERNARY_COLOR_CODE } from "../button";
import { Paragraph } from "../typography";
import Icon from "../icon";
import { SortButton, Container } from "./styled-components";
import { AnimatedDropdown } from "./components/animatedDropdown";
import { Option } from "./components/option";
import { SortContext } from "./context";

export const Sort = forwardRef<TouchableHighlight, SortProps>(
  (
    {
      children,
      qaLabel = "sort",
      buttonLabel,
      selectedLabel,
      selectedValue,
      onChange,
      closeOnChange = false,
      iconName,
      size = "m",
      isDisabled = false,
      isDarkMode = false,
      isContentCustom = false,
      onAction = "press",
      layer = 999,
      ...rest
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { events, eventsHandlers, hoverHandlers } = useEvents({});
    const onPress = useCallback(() => {
      setIsDropdownOpen((prevState) => !prevState);
    }, []);

    const onHoverOut = useCallback(() => {
      setIsDropdownOpen(false);
    }, []);

    const onHoverIn = useCallback(() => {
      if (onAction === "hover") {
        setIsDropdownOpen(true);
      }
    }, []);

    const hoverHandlersEdit = {
      ...hoverHandlers,
      onMouseEnter: () => onHoverIn(),
      onMouseLeave: () => onHoverOut()
    };

    const onChangeHandler = (val: string) => {
      onChange(val);
      if (isDropdownOpen && closeOnChange) {
        setIsDropdownOpen(false);
      }
    };

    return (
      <Container {...hoverHandlersEdit}>
        <SortButton
          ref={ref}
          accessibilityRole="combobox"
          variant={isDarkMode ? "tertiary_dark" : "tertiary"}
          size={size}
          isDisabled={isDisabled}
          disabled={isDisabled}
          onPress={onPress}
          qaLabel={qaLabel}
          tertiaryColor={TERNARY_COLOR_CODE}
          {...events}
          {...eventsHandlers}
          {...rest}
        >
          <>
            {buttonLabel && (
              <Paragraph
                qaLabel="button-label"
                fontFamily="medium"
                color={isDarkMode ? "blue_accent.000" : "grey.900"}
              >
                {selectedLabel ? `${buttonLabel}:` : buttonLabel}
              </Paragraph>
            )}
            {selectedLabel && (
              <Paragraph
                qaLabel="button-label"
                fontFamily="medium"
                color={TERNARY_COLOR_CODE}
                ml="space-1"
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {selectedLabel}
              </Paragraph>
            )}
            <Icon
              name={iconName ?? (isDropdownOpen ? "chevronUp" : "chevronDown")}
              size="s"
              lineColor={TERNARY_COLOR_CODE}
              ml="space-3"
            />
          </>
        </SortButton>
        <SortContext.Provider
          value={{
            isDropdownOpen,
            selectedValue,
            onChange: onChangeHandler,
            layer
          }}
        >
          {isContentCustom ? (
            children
          ) : (
            <AnimatedDropdown
              qaLabel={`${qaLabel}-dropdown`}
              isDarkMode={isDarkMode}
              width={rest.width}
            >
              {children}
            </AnimatedDropdown>
          )}
        </SortContext.Provider>
      </Container>
    );
  }
);

export { SortProps, SortButton, AnimatedDropdown, Option, SortContext };
export default Sort;
