import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import {
  AlertInlineStyleProps,
  AlertInlineContainerProps,
  AlertInlineDismissPressProp,
  AlertInlineIsLinkBellowProp,
  AlertInlineWrapperProps,
  AlertInlineHasTitleAndMessageProp,
  AlertInlineTypes
} from "./types";
import { variant, space, compose } from "../../theming/styledSystem";

export const alertInlinePressableVariants = {
  success: {
    base: "green.800",
    hover: "green.600",
    active: "green.700"
  },
  informational: {
    base: "blue_accent.700",
    hover: "blue_accent.600",
    active: "blue_accent.700"
  },
  error: {
    base: "red.700",
    hover: "red.600",
    active: "red.700"
  },
  warning: {
    base: "orange.900",
    hover: "orange.600",
    active: "orange.700"
  },
  promo: {
    base: "yellow.900",
    hover: "yellow.600",
    active: "yellow.700"
  }
};

export const alertToastPressableVariants = {
  success: {
    base: "white.900",
    hover: "white.700",
    active: "white.800"
  },
  informational: {
    base: "white.900",
    hover: "white.700",
    active: "white.800"
  },
  error: {
    base: "white.900",
    hover: "white.700",
    active: "white.800"
  },
  warning: {
    base: "white.900",
    hover: "white.700",
    active: "white.800"
  },
  promo: {
    base: "grey.900",
    hover: "black.900",
    active: "black.900"
  }
};

const alertInlineTextVariants = {
  prop: "variant",
  variants: {
    success: {
      color: "green.800"
    },
    informational: {
      color: "blue_accent.700"
    },
    error: {
      color: "red.700"
    },
    warning: {
      color: "orange.900"
    },
    promo: {
      color: "yellow.900"
    }
  }
};

const alertToastTextVariants = {
  prop: "variant",
  variants: {
    success: {
      color: "white.900"
    },
    informational: {
      color: "white.900"
    },
    error: {
      color: "white.900"
    },
    warning: {
      color: "white.900"
    },
    promo: {
      color: "grey.900"
    }
  }
};

export const alertInlineVariants = {
  prop: "variant",
  variants: {
    success: {
      backgroundColor: "green.100",
      border: `1px solid`,
      borderColor: "green.300"
    },
    informational: {
      backgroundColor: "blue_accent.000",
      border: `1px solid`,
      borderColor: "blue_accent.200"
    },
    error: {
      backgroundColor: "red.000",
      border: `1px solid`,
      borderColor: "red.200"
    },
    warning: {
      backgroundColor: "orange.000",
      border: `1px solid`,
      borderColor: "orange.200"
    },
    promo: {
      backgroundColor: "yellow.000",
      border: `1px solid`,
      borderColor: "yellow.200"
    }
  }
};

export const alertToastVariants = {
  prop: "variant",
  variants: {
    success: {
      backgroundColor: "green.600",
      boxShadow: "0px 1px 16px rgba(17, 43, 68, 0.25)"
    },
    informational: {
      backgroundColor: "blue_accent.600",
      boxShadow: "0px 1px 16px rgba(17, 43, 68, 0.25)"
    },
    error: {
      backgroundColor: "red.500",
      boxShadow: "0px 1px 16px rgba(17, 43, 68, 0.25)"
    },
    warning: {
      backgroundColor: "orange.500",
      boxShadow: "0px 1px 16px rgba(17, 43, 68, 0.25)"
    },
    promo: {
      backgroundColor: "yellow.500",
      boxShadow: "0px 1px 16px rgba(17, 43, 68, 0.25)"
    }
  }
};

const styledSystemStyles = (type: AlertInlineTypes) =>
  compose(
    space,
    type === "toast"
      ? variant(alertToastVariants)
      : variant(alertInlineVariants)
  );

export const AlertInlineContainer = styled(View).attrs<{
  qaLabel: string;
}>((props) => ({
  ...useQaLabel(props.qaLabel)
}))<AlertInlineContainerProps & AlertInlineIsLinkBellowProp>`
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  border-radius: ${({ theme }) => theme.radii.s};
  padding: ${({ theme, hasOnDismissPress, isLinkBellow }) => {
    if (hasOnDismissPress) {
      return isLinkBellow
        ? `0 0 ${theme.space["space-4"]} ${theme.space["space-4"]}`
        : `0 0 0 ${theme.space["space-4"]}`;
    }
    return theme.space["space-4"];
  }};
  ${({ type }) => styledSystemStyles(type || "floating")};
  ${(props) =>
    props.type === "fixed" &&
    css`
      border-left-width: 0px;
      border-right-width: 0px;
      border-radius: 0px;
    `}
`;

export const AlertInlineIconWrapper = styled(View)<AlertInlineDismissPressProp>`
  transform: translateY(1px);
  padding-top: ${({ theme, hasOnDismissPress }) =>
    hasOnDismissPress ? theme.space["space-4"] : "0"};
  padding-right: ${({ theme }) => theme.space["space-4"]};
`;

export const AlertInlineInfo = styled(View)<AlertInlineDismissPressProp>`
  padding-top: ${({ theme, hasOnDismissPress }) =>
    hasOnDismissPress ? theme.space["space-4"] : "0"};
  flex: 1;
`;

export const AlertInlineActions = styled(View)`
  flex-direction: row;
  align-items: flex-start;
`;

export const AlertInlineMessage = styled(Text).attrs<{
  qaLabel: string;
}>((props) => ({
  ...useQaLabel(props.qaLabel)
}))<
  AlertInlineStyleProps &
    AlertInlineWrapperProps &
    AlertInlineHasTitleAndMessageProp & { qaLabel?: string }
>`
  ${({ type }) =>
    type === "toast"
      ? variant(alertToastTextVariants)
      : variant(alertInlineTextVariants)};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme, type, hasTitleAndMessage }) => {
    if (type === "toast") {
      return hasTitleAndMessage ? theme.fonts.regular : theme.fonts.bold;
    }
    return theme.fonts.regular;
  }};
  margin-bottom: ${({ theme, hasOnDismissPress, isLinkBellow }) => {
    if (hasOnDismissPress && !isLinkBellow) return theme.space["space-4"];
    if (isLinkBellow) return theme.space["space-1"];
    return "0";
  }};
`;

export const AlertInlineLinkWrapper = styled(View)<
  AlertInlineDismissPressProp & AlertInlineIsLinkBellowProp
>`
  padding-top: ${({ theme, isLinkBellow, hasOnDismissPress }) => {
    if (hasOnDismissPress && !isLinkBellow) return theme.space["space-4"];
    if (isLinkBellow) return "2px";
    return "0";
  }};
`;

export const AlertInlineTitle = styled(Text)<AlertInlineStyleProps>`
  ${({ type }) =>
    type === "toast"
      ? variant(alertToastTextVariants)
      : variant(alertInlineTextVariants)}
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme, type }) =>
    type === "toast" ? theme.fonts.bold : theme.fonts.medium};
  margin-bottom: ${({ theme }) => theme.space["space-2"]}; ;
`;
