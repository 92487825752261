import React from "react";
import { useQaLabel } from "../../hooks/useQaLabel";
import { IconWithBackground } from "../iconWithBackground";
import { Error, Info, Promo, Success, Warning } from "./illustrations";
import { DialogMessageProps } from "./types";
import {
  StyledViewContainer,
  StyledParagraph,
  StyledTextTitle
} from "./styled-components";

const variantMap = {
  info: <Info />,
  warning: <Warning />,
  success: <Success />,
  error: <Error />,
  promo: <Promo />
};

export const DialogMessage = ({
  title,
  description,
  illustration,
  variant,
  qaLabel = "dialog-message",
  ...rest
}: DialogMessageProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  return (
    <StyledViewContainer {...viewTestProps} {...rest}>
      {variant ? variantMap[variant] : illustration}
      <StyledTextTitle>{title}</StyledTextTitle>
      <StyledParagraph qaLabel="description">{description}</StyledParagraph>
    </StyledViewContainer>
  );
};

export { DialogMessageProps, IconWithBackground };
export default DialogMessage;
