import React, { useContext, useEffect } from "react";
import { LayoutChangeEvent } from "react-native";
import { useEvents } from "../../../../hooks/useEvents";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { useTheme } from "../../../../theming";
import { TabsContext } from "../../tabs";
import {
  StyledPressable,
  StyledTextTitle,
  StyledViewIconContainer,
  StyledViewTitleContainer,
  StyledTextDescription
} from "./styled-components";
import { TabProps } from "./types";

export { TabProps };

export const Tab = ({
  value,
  title,
  description,
  counter,
  isSelected = false,
  isDisabled = false,
  isStretched,
  qaLabel = value
}: TabProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { colors } = useTheme();
  const { events, setEvents, eventsHandlers } = useEvents({});
  const {
    selectedValue,
    onChange = () => {},
    setTabsLayout
  } = useContext(TabsContext);

  useEffect(() => {
    setEvents({ ...events, isSelected });
  }, [isSelected]);

  useEffect(() => {
    setEvents({
      ...events,
      isSelected: isSelected || selectedValue === value
    });
  }, [selectedValue]);

  const pressHandler = () => {
    onChange(value);
  };

  const onLayoutHandler = (event: LayoutChangeEvent, tab: string) => {
    const { width, x } = event.nativeEvent.layout;

    const newState = {
      [tab]: { width, x }
    };

    setTabsLayout((state: {}) => ({ ...state, ...newState }));
  };

  return (
    <StyledPressable
      accessibilityRole="tab"
      onPress={pressHandler}
      isStretched={isStretched}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onLayout={(event) => onLayoutHandler(event, value)}
      onLongPress={pressHandler}
      underlayColor={colors.blue_accent[100]}
      {...eventsHandlers}
      {...events}
      {...viewTestProps}
    >
      <>
        <StyledViewTitleContainer>
          <StyledTextTitle {...events} isDisabled={isDisabled}>
            {title}
          </StyledTextTitle>
          {!isDisabled && counter && (
            <StyledViewIconContainer>{counter}</StyledViewIconContainer>
          )}
        </StyledViewTitleContainer>
        {description && (
          <StyledTextDescription {...events} isDisabled={isDisabled}>
            {description}
          </StyledTextDescription>
        )}
      </>
    </StyledPressable>
  );
};
