// @flow

import MobileDetect from "mobile-detect";

import type { Device } from "./types.js.flow";
import { PRODUCTS_MOBILE } from "./product";

export default (
  userAgent: ?string = undefined,
  product: ?string = undefined
): Device => {
  const uaInfo = new MobileDetect(userAgent);
  let device = "desktop";
  device = (uaInfo.mobile() && "mobile") || device;
  device = (uaInfo.tablet() && "tablet") || device;

  // tablet exception
  if (device === "desktop") {
    return PRODUCTS_MOBILE.includes(product) ? "tablet" : device;
  }

  return device;
};
