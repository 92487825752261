"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _mobileDetect = _interopRequireDefault(require("mobile-detect"));

var _brand = _interopRequireWildcard(require("./brand"));

var _environment = _interopRequireDefault(require("./environment"));

var _content = _interopRequireDefault(require("./content"));

var _device = _interopRequireDefault(require("./device"));

var _brazeDevice = _interopRequireDefault(require("./brazeDevice"));

var _product = _interopRequireWildcard(require("./product"));

var _location = _interopRequireDefault(require("./location"));

var _features = _interopRequireDefault(require("./features"));

var _messages = _interopRequireDefault(require("./messages"));

var _metadata = _interopRequireDefault(require("./metadata"));

var _buildurl = _interopRequireDefault(require("./buildurl"));

var _states = _interopRequireDefault(require("./states"));

var _graphContext = _interopRequireDefault(require("./graphContext"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const OS_IOS = "iOS";
let hostname;
let userAgent;
let setProduct = "tvg4";

var _default = (givenHostname = hostname, givenUserAgent = userAgent, givenProduct = setProduct) => {
  if (!givenHostname && typeof window !== "undefined" && window.location) {
    hostname = window.location.hostname;
  } else {
    hostname = (0, _lodash.get)(global, "appConf.hostname", null) || givenHostname;
  }

  if (!givenUserAgent && typeof window !== "undefined" && window.navigator) {
    userAgent = window.navigator.userAgent;
  } else {
    userAgent = (0, _lodash.get)(global, "appConf.userAgent", null) || givenUserAgent;
  }

  setProduct = (0, _lodash.get)(global, "appConf.product", null) || givenProduct || setProduct;
  const uaInfo = new _mobileDetect.default(userAgent);
  const environment = (0, _lodash.get)(global, "appConf.environment", null) || (0, _environment.default)(hostname);
  const product = (0, _product.default)(setProduct);
  const device = (0, _lodash.get)(global, "appConf.device", null) || (0, _device.default)(userAgent, product);
  const os = _product.PRODUCTS_IOS.includes(product) ? OS_IOS : uaInfo.os(userAgent);

  const features = _features.default.readFeatures();

  const messages = _messages.default.readMessages();

  const metadata = (0, _metadata.default)();
  const configs = (0, _content.default)(environment);
  const brand = _product.PRODUCTS_FDR.includes(product) ? _brand.BRAND_FDR : (0, _brand.default)(hostname);
  const brandConfig = configs[brand];
  const location = (0, _location.default)(brand);
  const isRN = (0, _utils.isRN)();
  const gaContext = `google.${product === "androidwrapper" && brand === "fdr" ? "fdrandroidwrapper" : product}`;
  const amplitudeContext = `amplitude.${product}`;
  const brazeDevice = (0, _brazeDevice.default)(os, product);
  return {
    brand,
    device,
    environment,
    product,
    features,
    messages,
    metadata,
    os,
    brazeDevice,
    context: (productContext = product, forceStandalone = false) => `${forceStandalone && _product.PRODUCTS_FDR_X_SELL.includes(productContext) ? productContext.replace("xsell", "") : productContext}-${_product.PRODUCTS_FDR.includes(productContext) ? _brand.BRAND_FDR : brand}`,
    config: configPath => (0, _lodash.get)(brandConfig, configPath, brandConfig),
    gaConfig: () => (0, _lodash.get)(brandConfig, gaContext, brandConfig),
    amplitudeConfig: () => (0, _lodash.get)(brandConfig, amplitudeContext, brandConfig),
    getFeatures: (localDevice = device, serverSide = false) => _features.default.getFeatures(brandConfig.service, _product.PRODUCTS_FDR_X_SELL.includes(product) ? product.replace("xsell", "") : product, localDevice, brand, serverSide),
    getMessages: (namespaces, forceFetch = false) => _messages.default.getMessages(namespaces, brandConfig.service.capi, _product.PRODUCTS_FDR_X_SELL.includes(product) ? product.replace("xsell", "") : product, device, brand, forceFetch),
    getStates: () => _states.default,
    getDomains: (name = "desktop") => {
      const brandMap = Object.keys(configs);
      return brandMap.map(configBrand => configs[configBrand].domain[name]);
    },
    getClientId: () => (0, _utils.isBrowser)() ? btoa(`${brandConfig.clientId}:`) : Buffer.from(`${brandConfig.clientId}:`).toString("base64"),
    getExternalDomain: () => (0, _lodash.get)(brandConfig, "externalDomain", {}),
    buildUrl: options => {
      const {
        app = product,
        wrapper = false,
        callback = false,
        path = "",
        params = null,
        relative = false
      } = options;
      return (0, _buildurl.default)(app, wrapper, callback, path, params, relative)(brandConfig.domain, brand === _brand.BRAND_FDR ? _brand.BRAND_FDR : location, product, brazeDevice);
    },
    graphContext: () => (0, _graphContext.default)(brand, product, device),
    isRN,
    isBrowser: _utils.isBrowser
  };
};

exports.default = _default;
module.exports = exports.default;