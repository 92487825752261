import styled, { css } from "styled-components/native";
import { Pressable } from "react-native";
import { FilterButtonProps, ButtonStates } from "./types";

const buttonSizes = {
  s: css`
    height: 25px;
    padding: 4px 7px;
  `,
  m: css`
    height: 32px;
    padding: 6px 7px;
  `,
  l: css`
    height: 44px;
    padding: 12px 15px;
  `
} as const;

const buttonStates = {
  default: css`
    background: transparent;
    border: 1px solid transparent;
  `,
  hover: css`
    background: ${({ theme }) => theme.colors.blue_accent["000"]};
    border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  `,
  active: css`
    background: ${({ theme }) => theme.colors.blue_accent["100"]};
    border: 1px solid ${({ theme }) => theme.colors.blue_accent["500"]};
    z-index: 1;
  `,
  pressed: css`
    background: ${({ theme }) => theme.colors.blue_accent["100"]};
    border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  `
} as const;

export const StyledButton = styled(Pressable)<
  Pick<FilterButtonProps, "size" | "iconSide" | "minWidth" | "isStretch"> &
    ButtonStates
>`
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  flex-direction: ${({ iconSide }) =>
    iconSide === "left" ? "row" : "row-reverse"};
  gap: 10px;
  line-height: 18px;
  margin: 0 -1px;
  transition: all 0.2s ease-in-out;

  @media not all and (-moz-appearance: none) {
    /* CSS properties here will apply to all browsers except Firefox */
    width: max-content;
  }

  ${({ isStretch }) =>
    isStretch &&
    css`
      flex: 1;
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  ${({ size }) => buttonSizes[size as FilterButtonProps["size"]]}
  ${({ isHovered, isPressed, isActive }) => {
    if (isActive) return buttonStates.active;
    if (isPressed) return buttonStates.pressed;
    if (isHovered) return buttonStates.hover;
    return buttonStates.default;
  }}
`;

export const StyledText = styled.Text<
  Pick<ButtonStates, "isActive"> & Pick<FilterButtonProps, "size">
>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.blue_accent["700"] : theme.colors.grey["900"]};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme, size }) =>
    size === "s" ? theme.fontSizes.xs : theme.fontSizes.s};
`;
