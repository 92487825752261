"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PRODUCTS_MOBILE = exports.PRODUCTS_ANDROID = exports.PRODUCTS_FDR = exports.PRODUCTS_FDR_X_SELL = exports.PRODUCTS_IOS = exports.PRODUCTS_WEB = void 0;
const PRODUCTS_WEB = ["touch2", "touch3", "fdrmobile"];
exports.PRODUCTS_WEB = PRODUCTS_WEB;
const PRODUCTS_IOS = ["ios", "ios2", "fdrios", "fdriosxsell", "iosnative"];
exports.PRODUCTS_IOS = PRODUCTS_IOS;
const PRODUCTS_FDR_X_SELL = ["fdriosxsell", "fdrandroidxsell"];
exports.PRODUCTS_FDR_X_SELL = PRODUCTS_FDR_X_SELL;
const PRODUCTS_FDR = ["fdrmobile", "fdrios", "fdrandroid", ...PRODUCTS_FDR_X_SELL];
exports.PRODUCTS_FDR = PRODUCTS_FDR;
const PRODUCTS_ANDROID = ["androidnative", "fdrandroid", "androidwrapper", "tvgandroid", "fdrandroidxsell"];
exports.PRODUCTS_ANDROID = PRODUCTS_ANDROID;
const PRODUCTS_MOBILE = [...new Set([...PRODUCTS_WEB, ...PRODUCTS_IOS, ...PRODUCTS_FDR, ...PRODUCTS_ANDROID])];
exports.PRODUCTS_MOBILE = PRODUCTS_MOBILE;

var _default = product => {
  if (typeof window !== "undefined" && window.__TVG_GLOBALS__ && window.__TVG_GLOBALS__.PRODUCT) {
    return window.__TVG_GLOBALS__.PRODUCT;
  }

  return product;
};

exports.default = _default;