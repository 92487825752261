"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AW_ENV_MAPPING = void 0;

var _utils = require("./utils");

const AW_ENV_MAPPING = {
  dev: "dev",
  qa: "qa",
  staging: "prod",
  production: "prod"
};
exports.AW_ENV_MAPPING = AW_ENV_MAPPING;
const env = ["production", "staging", "qa"];
const defaultEnv = env[0];

const getEnvironmentByHostname = hostname => {
  if (hostname === "10.0.2.2") {
    return "qa";
  }

  if (!hostname) {
    return defaultEnv;
  }

  if (/(stage\.|staging\.|cert\.)/gi.test(hostname)) {
    return "staging";
  }

  if (/qa\.|dev\./gi.test(hostname)) {
    return "qa";
  }

  return defaultEnv;
};

const getEnvironmentInBrowser = () => !!window.__TVG_GLOBALS__ && !!window.__TVG_GLOBALS__.ENVIRONMENT && window.__TVG_GLOBALS__.ENVIRONMENT;

const getEnvironmentInServer = () => typeof process.env.ENVIRONMENT === "string" && (process.env.ENVIRONMENT === "dev" ? "qa" : env.includes(process.env.ENVIRONMENT) && process.env.ENVIRONMENT);

var _default = hostname => (0, _utils.isBrowser)() && window.__TVG_GLOBALS__ ? getEnvironmentInBrowser() || getEnvironmentByHostname(hostname) : getEnvironmentInServer() || getEnvironmentByHostname(hostname);

exports.default = _default;