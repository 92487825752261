import React, { memo, useState, forwardRef } from "react";
import { Platform, TextInput } from "react-native";
import { useQaLabel } from "../../../hooks";
import type { InputPasswordProps } from "../types";
import { InputWrapper, TopWrapper } from "../styled-components";
import { InputField } from "../inputField";
import { InputLabel } from "../inputLabel";
import { InputMessage } from "../inputMessage";
import { Link } from "../../link";
import { IconButton } from "../../iconButton";

export const InputPassword = forwardRef<TextInput, InputPasswordProps>(
  (
    {
      placeholder = "",
      hasPasswordIcon = true,
      label = "",
      messageAbove = "",
      messageBellow = "",
      isChecked = false,
      isInvalid = false,
      warningMessage = "",
      recoveryLink,
      qaLabel,
      onBlur,
      onChange,
      onFocus,
      ...rest
    }: InputPasswordProps,
    ref
  ) => {
    const [show, setShow] = useState(false);
    const isOptional = false;
    const isEdgBrowser =
      Platform.OS === "web" ? navigator.userAgent.indexOf("Edg") > -1 : false;

    return (
      <InputWrapper
        accessibilityState={{ checked: show }}
        {...rest}
        {...useQaLabel(qaLabel)}
      >
        <TopWrapper>
          <InputLabel
            label={label}
            isOptional={isOptional}
            isChecked={isChecked}
          />
          {recoveryLink && (
            <Link href={recoveryLink.url} qaLabel="recovery-link">
              {recoveryLink.text}
            </Link>
          )}
        </TopWrapper>
        {messageAbove !== "" && (
          <InputMessage message={messageAbove} marginTop="space-3" />
        )}
        <InputField
          ref={ref}
          type={show ? "text" : "password"}
          placeholder={placeholder}
          autoCapitalize="none"
          isInvalid={isInvalid}
          qaLabel={`${qaLabel}-input-field`}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          {...rest}
          end={
            hasPasswordIcon && !isEdgBrowser ? (
              <IconButton
                textTooltip={show ? "Hide" : "Show"}
                variant="tertiary"
                size="s"
                tertiaryColor="blue_accent.500"
                qaLabel="show-hide-icon"
                onPress={() => setShow(!show)}
                iconName={show ? "eyeHide" : "eyeShow"}
                marginRight="space-2"
              />
            ) : undefined
          }
          my="space-2"
          mx={0}
        />
        {isInvalid && warningMessage && (
          <InputMessage
            message={warningMessage}
            isWarning={isInvalid}
            mx={0}
            mt={2}
            mb="space-3"
          />
        )}
        {messageBellow !== "" && <InputMessage message={messageBellow} />}
      </InputWrapper>
    );
  }
);

export default memo(InputPassword);
