// @flow
const { STORYBLOK_TOKENS } = require("../../utils/fdr/storyblok.js");
const { APPTENTIVE_TOKENS } = require("../../utils/fdr/apptentive.js");

module.exports = {
  domain: {
    desktop: "racing.qa.fndl.dev",
    mobile: "racing.qa.fndl.dev",
    talentPicks: "racing.qa.fndl.dev/talent-picks/",
    accountWallet: "account.racing.qa.fndl.dev"
  },
  service_host: "racing.qa.fndl.dev",
  externalDomain: {
    FDTermsConditions: "https://www.qa.fndl.dev/terms",
    FDPolicy: "https://www.qa.fndl.dev/privacy",
    FDRLoginBridge: "https://www.qa.fndl.dev/sso-auth/fdracing",
    FDRSignup:
      "https://www.qa.fndl.dev/join?next=%2Fsso-auth%2Ffdracing%3Fredirect_uri%3D"
  },
  externalServices: {
    fanduel: "https://devstack-igtqa8-api.use1.dev.us.fdbox.net"
  },
  clientId: "a301e728606047a5b6522727d495b3ed",
  brazeId: "d1c732dc-ad0d-49a5-acb7-d360cd0fee38",
  storyblokToken: STORYBLOK_TOKENS.QA,
  storyblokVersion: "draft",
  siftAccountId: "60f88a09293fb116c8220753",
  siftApiKey: "9cc4454a4bfac8e3",
  siftBeaconKey: "d9c3d7901c",
  appTentiveIos: APPTENTIVE_TOKENS.IOS.QA,
  appTentiveAndroid: APPTENTIVE_TOKENS.ANDROID.QA,
  appTentiveWeb: APPTENTIVE_TOKENS.WEB.QA
};
