import { useColor } from "../../../theming/hooks/useColor";
import { tagVariants } from "../styled-components";

type Variant = keyof typeof tagVariants.variants;

export const useVariantColors = (variant: Variant) => {
  const { variants } = tagVariants;

  let bgColorToken = "";
  let colorToken = "";

  Object.entries(variants).forEach((val) => {
    const [variantName, colors] = val;
    if (variantName === variant) {
      bgColorToken = colors.backgroundColor;
      colorToken = colors.color;
    }
  });

  const bgColor = useColor(bgColorToken);
  const color = useColor(colorToken);
  return { bgColor, color };
};
