// @flow
const { AMPLITUDE_API_KEYS } = require("../../utils/urp/amplitude.js");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_FDR,
  AMPLITUDE_API_KEYS_XSELL: AMPLITUDE_API_KEYS_FDR_XSELL
} = require("../../utils/fdr/amplitude.js");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_MEP
} = require("../../utils/mep/amplitude.js");

// @flow
module.exports = {
  service_host: "service.tvg.com",
  domain: {
    desktop: "www.tvg.com",
    mobile: "www.tvg.com",
    talentPicks: "www.tvg.com/talent-picks/"
  },
  externalDomain: {
    support: "https://support.tvg.com",
    contactSupport:
      "https://support.tvg.com/tvg/s/articles/360005946853-Contact-Support",
    NCPGamblingChat: "http://www.ncpgambling.org/chat"
  },
  google: {
    tvg4: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "desktop",
      productVersion: "TVG4"
    },
    tvg5: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "desktop",
      productVersion: "TVG5"
    },
    touch2: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    ios: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "ios_native"
    },
    touch3: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    ios2: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "ios_native"
    },
    fdrmobile: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    fdrios: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "ios_native",
      productVersion: "MEP"
    },
    fdrandroid: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdrandroidwrapper: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdriosxsell: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "x-sell",
      productVersion: "MEP"
    },
    fdrandroidxsell: {
      gaId: "UA-8156856-32",
      gtmId: "NSHBQSR",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "x-sell",
      productVersion: "android_apk"
    },
    androidwrapper: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "android_native",
      productVersion: "android_gps"
    },
    tvgandroid: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    iosnative: {
      gaId: "UA-8156856-2",
      gtmId: "KTDSBG",
      gtmEnv: "",
      gtmAuth: "",
      siteVersion: "ios_native",
      productVersion: "ios_native"
    },
    gcs: {
      url: "https://storage.googleapis.com",
      tvgStatic: "tvg-static"
    }
  },
  amplitude: {
    tvg5: {
      amplitudeAPI: AMPLITUDE_API_KEYS.PRODUCTION,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "tvg_urp"
    },
    fdrmobile: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.PRODUCTION,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "fdr_standalone_lobby"
    },
    fdrios: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.PRODUCTION,
      siteVersion: "native",
      sitePlatform: "ios",
      product: "fdr_standalone_lobby"
    },
    fdrandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.PRODUCTION,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "self_dist"
    },
    fdrandroidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.PRODUCTION,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "google_playstore"
    },
    fdriosxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL.PRODUCTION,
      siteVersion: "x-sell",
      sitePlatform: "ios",
      product: "fdr_xsell_lobby"
    },
    fdrandroidxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL.PRODUCTION,
      siteVersion: "x-sell",
      sitePlatform: "android",
      product: "fdr_xsell_lobby"
    },
    touch3: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.PRODUCTION,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "tvg_mep"
    },
    androidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.PRODUCTION,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    },
    tvgandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.PRODUCTION,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    }
  },
  brazeId: "908c5857-650b-4f31-8ec7-63b15dc11d1d",
  siftAccountId: "60f88a09293fb116c8220750",
  siftApiKey: "2cd7fd28042a1454",
  siftBeaconKey: "df80113946",
  clientId: "eaf77f127ea003d4e275a3ed07d6f527",
  perimeterxId: "TK74ox9H",
  storyblokToken: "mBZqhnrYeUYWqvVh3OTCDgtt",
  storyblokVersion: "published",
  placesAPI: "AIzaSyBwhD0RAB-vMDrx4IDcO_gezIVGpn7dFks",
  paypalID:
    "AfOWmRjlT73hGanCAUypmRtFas_NsPcXek2_fL8XOIrJHBDoUxcSEVBQ72Ls4fc_2xgJSWK3gTDrgjhI",
  apptentiveID: "61f087a65a8827645c000039",
  apptentiveIosKey: "IOS-TVG-HORSE-RACING-BETTING-APP",
  apptentiveIosSignature: "c0b078503398c5fec509b1235058c955",
  apptentiveAndroidKey: "ANDROID-TVG-PRODUCTION",
  apptentiveAndroidSignature: "b09205fa5b98d7fdc161f1ca98b5d9b1",
  sonarQube: {
    serverUrl: "https://sonarqube.gcp-dev.tvg.com",
    token: "8836caf742bb1c13755da2d957ba5d1674adbc43",
    projectKey: "tvg-monorepo"
  },
  dataDogRum: {
    desktopApplicationId: "123b949b-108c-4306-bea0-9227242f5b17",
    desktopClientToken: "pub748e107dd1bd28256dad4b1425dda2e3",
    mobileApplicationId: "03715ae6-b7e2-4b30-ac7b-cf6a5bfdf763",
    mobileClientToken: "pub333a7706c3a954d17d9db1becba22673",
    site: "datadoghq.com"
  }
};
