import React, { forwardRef } from "react";
import { TouchableHighlight, GestureResponderEvent } from "react-native";

import { useQaLabel } from "../../hooks/useQaLabel";
import { useEvents } from "../../hooks/useEvents";
import { Paragraph } from "../typography";
import { LinkItemProps, LinkItemCompoundComponent } from "./types";
import {
  StyledComponent,
  ContentWrapper,
  StyledContent,
  StyledHeader,
  StyledDivider,
  IconContainer
} from "./styled-components";
import { Icon } from "../icon";
import { LinkItemList } from "./linkItemList";

export const LinkItem = forwardRef<TouchableHighlight, LinkItemProps>(
  (
    {
      title,
      description,
      isDisabled = false,
      start,
      onPress,
      qaLabel,
      hasDividerLine = false,
      hasArrowRight = true,
      ...rest
    },
    ref
  ) => {
    const { events, eventsHandlers } = useEvents({}, !isDisabled);

    const marginByIconSize = (iconSize: string) => {
      switch (iconSize) {
        case "l":
          return { mt: 6 };
        case "m":
          return { mt: 8 };
        default:
          return { mt: 2 };
      }
    };

    const handleMargin = () =>
      typeof title?.render === "object" && title?.iconSize
        ? marginByIconSize(title.iconSize)
        : { mt: 0 };

    return (
      <StyledComponent
        // @ts-ignore
        ref={ref}
        accessibilityRole="button"
        isDisabled={isDisabled}
        hasDividerLine={hasDividerLine}
        onPress={(event: GestureResponderEvent) => {
          if (typeof onPress === "function" && !isDisabled) onPress(event);
        }}
        {...events}
        {...eventsHandlers}
        {...rest}
        {...useQaLabel(qaLabel)}
      >
        <>
          <ContentWrapper>
            {start}
            <StyledContent>
              {!!title &&
                (React.isValidElement(title.render) ? (
                  title.render
                ) : (
                  <StyledHeader qaLabel={`${qaLabel}-title`} tag="h3">
                    {title.render}
                  </StyledHeader>
                ))}
              <Paragraph
                color="grey.800"
                qaLabel={`${qaLabel}-description`}
                {...handleMargin()}
              >
                {description}
              </Paragraph>
            </StyledContent>
          </ContentWrapper>
          {!isDisabled && onPress && hasArrowRight && (
            <IconContainer iconSize={title?.iconSize}>
              <Icon
                name="chevronRight"
                size="s"
                lineColor="grey.800"
                // @ts-ignore - avoid keyboard tab flow
                focusable={false}
              />
            </IconContainer>
          )}
          {hasDividerLine && <StyledDivider />}
        </>
      </StyledComponent>
    );
  }
) as LinkItemCompoundComponent<typeof LinkItemList>;

export { LinkItemProps, StyledHeader as LinkItemTitle };
LinkItem.List = LinkItemList;
export default LinkItem;
